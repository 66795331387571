import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

import PhoneIcon from '../../../static/svgs/CallUs.svg';
import EmailIcon from '../../../static/svgs/Email.svg';
import HomeIcon from '../../../static/svgs/Location.svg';
import WorldIcon from '../../../static/svgs/Website.svg';

import SideBarRow from '../TopBar/NavBar/SideBar/SideBarRow/SideBarRow';

import ContactUsImage from '../../../static/svgs/ContactUs.svg';
import poweredByYAPI from '../../../static/svgs/PoweredbyYAPI.svg';

const iconSizes = {
  pS: {width: '14px', height: '14px', margin: 'auto'},
  pM: {width: '20px', height: '20px', margin: 'auto'},
  pL: {width: '20px', height: '20px', margin: 'auto'},
  eS: {width: '17px', height: '12px', margin: 'auto'},
  eM: {width: '22px', height: '16px', margin: 'auto'},
  eL: {width: '22px', height: '16px', margin: 'auto'},
  aS: {width: '13px', height: '17px', margin: 'auto'},
  aM: {width: '19px', height: '26px', margin: 'auto'},
  aL: {width: '19px', height: '26px', margin: 'auto'},
  wS: {width: '15px', height: '15px', margin: 'auto'},
  wM: {width: '19px', height: '19px', margin: 'auto'},
  wL: {width: '19px', height: '19px', margin: 'auto'},
}

const styles = theme => ({
  imageS: {
    width: '187px',
  },
  imageM: {
    width: '256px',
  },
  imageL: {
    width: '323px',
    height: '323px',
  },
  imageGridS: {
    marginTop: '16px',
    marginBottom: '8px',
  },
  imageGridM: {
    marginTop: '56px'
  },
  imageGridL: {
    marginTop: '56px'
  },
  smallTextS: {
    marginTop: '8px',
    marginBottom: '32px',
    textAlign: 'center',
    maxWidth: '239px',
  },
  smallTextM: {
    marginTop: '20px',
    marginBottom: '24px',
    textAlign: 'center',
    maxWidth: '331px',
  },
  smallTextL: {
    marginTop: '24px',
    marginBottom: '40px',
    // textAlign: 'center',
    maxWidth: '500px',
  },
  centerText: {
    textAlign: 'center',
  },
  mainS: {
    marginBottom: '77px',
  },
  mainM: {
    marginBottom: '129px',
  },
  mainL: {
    height: '100%',
    width: '100%',
    maxWidth: '866px',
  },
  leftGrid: {
    height: '323px',
    width: '500px',
  },
  wide: {
    width: '100%',
    marginBottom: '16px',
  },
  bigTextS: {
    maxWidth: '239px',
    marginTop: '8px',
  },
  bigTextM: {
    maxWidth: '331px',
    marginTop: '16px',
  },
  bigTextL: {
    maxWidth: '500px',
    marginTop: '33px',
  },
  powerGridS: {
    marginBottom: '16px',
    marginTop: '31px',
  },
  powerGridM: {
    marginBottom: '48px',
    marginTop: '45px',
  },
  powerImageS: {
    height: '18px',
    width: '92px',
  },
  powerImageM: {
    height: '24px',
    width: '124px',
  },
});

const ContactUs = (props) => {
  const {
    classes,
    size,
    data,
  } = props;
  console.log(data);
  if (size === 'l') {
    return (
      <Grid
        container
        alignItems = 'center'
        direction = 'row'
        justify = 'space-between'
        className = {classes.mainL}
      >
        <Grid
          item
          className = {classes.leftGrid}
        >
          <Grid
            container
            alignItems = 'flex-start'
            direction = 'column'
          >
            <Grid
              item
              className = {classes.bigTextL}
            >
              <Typography
                variant = 'h1'
              >
                Contact Us
              </Typography>
            </Grid>
            <Grid
              item
              className = {classes.smallTextL}
            >
              <Typography
                variant = 'body1'
                // className = {classes.centerText}
              >
                Please give us a call to schedule an appointment.
              </Typography>
            </Grid>
            <Grid
              item
              className = {classes.wide}
            >
              <Grid
                container
              >
                { data.phone && (
                  <Grid
                    item
                    className = {classes.gridButtonL}
                  >
                    <SideBarRow
                      icon = {<img src={PhoneIcon} alt='Call Us' style={size === 'm' ? iconSizes.pM : iconSizes.pS} />}
                      link = {`tel:+1${data.phone}`}
                      size = {size}
                      text = 'Call Us'
                      tooltipText = {data.phone}
                    />
                  </Grid>
                )}
                { data.email && (
                  <Grid
                    item
                    className = {classes.gridButtonL}
                  >
                    <SideBarRow
                      link = {`mailto:${data.email}`}
                      icon = {<img src={EmailIcon} alt='Email Us' style={size === 'm' ? iconSizes.eM : iconSizes.eS} />}
                      size = {size}
                      text = 'Email Us'
                      tooltipText = {data.email}
                    />
                  </Grid>
                )}
                { data.address && data.google_place_id && (
                  <Grid
                    item
                    className = {classes.gridButtonL}
                  >
                    <SideBarRow
                      link = {`https://www.google.com/maps/search/?api=1&query=${data.ddress}&query_place_id=${data.google_place_id}`}
                      icon = {<img src={HomeIcon} alt='Our Location' style={size === 'm' ? iconSizes.aM : iconSizes.aS} />}
                      size = {size}
                      text = 'Find Us'
                      tooltipText = {data.address}
                    />
                  </Grid>

                )}
                { data.web && (
                  <Grid
                    item
                    className = {classes.gridButtonL}
                  >
                    <SideBarRow
                      link = {data.web}
                      icon = {<img src={WorldIcon} alt='Our Website' style={size === 'm' ? iconSizes.wM : iconSizes.wS} />}
                      size = {size}
                      text = 'Visit Us'
                      tooltipText = {data.web}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className = {classes.imageGridL}
        >
          <img
            alt = "Logo"
            src = {ContactUsImage}
            className = {classes.imageL}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        alignItems = 'center'
        direction = 'column'
        justify = 'center'
        className = {
          size === 'm' ? classes.mainM 
          : classes.mainS
        }
      >
        <Grid
          item
          className = {
            size === 'm' ? classes.imageGridM 
            : classes.imageGridS
          }
        >
          <img
            alt = "Logo"
            src = {ContactUsImage}
            className = {
              size === 'm' ? classes.imageM 
              : classes.imageS
            }
          />
        </Grid>
        <Grid
          item
          className = {classes.bigText}
        >
          <Typography
            variant = 'h1'
          >
            Contact Us
          </Typography>
        </Grid>
        <Grid
          item
          className = {
            size === 'm' ? classes.smallTextM 
            : classes.smallTextS
          }
        >
          <Typography
            variant = 'h2'
            className = {classes.centerText}
          >
            Please give us a call to schedule an appointment.
          </Typography>
        </Grid>
        <Grid
          item
        >
          { data.practice_phone && (
            <SideBarRow
              contact
              link = {`tel:+1${data.practice_phone}`}
              icon = {<img src={PhoneIcon} alt='Call Us' style={size === 'm' ? iconSizes.pM : iconSizes.pS} />}
              size = {size}
              text = 'Call Us'
            />
          )}
          { data.practice_email && (
            <SideBarRow
              contact
              link = {`mailto:${data.practice_email}`}
              icon = {<img src={EmailIcon} alt='Email Us' style={size === 'm' ? iconSizes.eM : iconSizes.eS} />}
              size = {size}
              text = 'Email Us'
            />
          )}
          { data.practice_address && (
            <SideBarRow
              contact
              link = {`https://www.google.com/maps/search/?api=1&query=${data.practice_address}`}
              icon = {<img src={HomeIcon} alt='Our Location' style={size === 'm' ? iconSizes.aM : iconSizes.aS} />}
              size = {size}
              text = 'Find Us'
            />
          )}
          { data.practice_web && (
            <SideBarRow
              contact
              link = {data.practice_web}
              icon = {<img src={WorldIcon} alt='Our Website' style={size === 'm' ? iconSizes.wM : iconSizes.wS} />}
              size = {size}
              text = 'Visit Us'
            />
          )}
        </Grid>
        <Grid
          item
          className = {
            size === 'm' ? classes.powerGridM 
            : classes.powerGridS
          }
        >
          <img
            alt = 'Powered By YAPI'
            src = {poweredByYAPI}
            className = {
              size === 'm' ? classes.powerImageM 
              : classes.powerImageS
            }
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ContactUs);