import React, {
  Component,
} from 'react';

import {
  ClickAwayListener,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import PropTypes from 'prop-types';

import QuestionIcon from '../../../static/svgs/hatena.svg';

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const useStylesArrow = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.background.tooltip,
    borderRadius: '10px',
    boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.25)`,
    position: 'relative',
    maxWidth: '423px',
    whiteSpace: 'pre-line',
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: arrowGenerator(theme.palette.background.tooltip),
}));

function ArrowTooltip(props) {
  const { arrow, ...classes } = useStylesArrow();
  const [arrowRef, setArrowRef] = React.useState(null);

  return (
    <Tooltip
      classes = {classes}
      PopperProps = {{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      {...props}
      title = {
        <React.Fragment>
          {props.title}
          <span className={arrow} ref={setArrowRef} />
        </React.Fragment>
      }
      leaveTouchDelay = {10000}
      // leaveDelay = {1000}
      // disableHoverListener
      // interactive
    />
  );
}

ArrowTooltip.propTypes = {
  title: PropTypes.node,
};

const styles = theme => ({
  questionS: {
    width: '16px',
    height: '16px',
    marginLeft: '4px',
  },
  questionM: {
    width: '24px',
    height: '24px',
    marginLeft: '8px',
    marginBottom: '-4px',
  },
  questionL: {
    width: '24px',
    height: '24px',
    marginLeft: '8px',
  },
  textS: {
    width: '230px',
    padding: '0px 9px 10px',
  },
  textM: {
    width: '391px',
    padding: '0px 16px 7px'
  },
  textL: {
    width: '391px',
    padding: '0px 16px 7px'
  },
  titleS: {
    marginBottom: '8px',
  },
  titleM: {
    marginBottom: '8px',
  },
  titleL: {
    marginTop: '4px',
    marginBottom: '8px',
  },
});

class Tooltips extends Component {
  state = {
    open: false,
  };

  handleClose = () => {
    // console.log('closing');
    this.setState({
      open: false,
    });
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  }

  render() {
    const {
      classes,
      size,
      text,
    } = this.props;
    const {
      open,
    } = this.state;
    return(
      <ClickAwayListener
        onClickAway = {this.handleClose}
      >
        <div>
          <ArrowTooltip
            title = {
              <Typography
                align = 'center'
                variant = {
                  size === 'l' ? 'h6'
                  : 'subtitle2'
                }
                className = {
                  size === 'l' ? classes.textL
                  : size === 'm' ? classes.textM
                  : classes.textS
                }
              >
                {text}
              </Typography>
            }
            open = {open}
            onClose = {this.handleClose}
            disableHoverListener
          >
            <img
              alt = '?'
              src = {QuestionIcon}
              className = {
                size === 'l' ? classes.questionL
                : size === 'm' ? classes.questionM
                : classes.questionS
              }
              onClick = {this.handleOpen}
            />
          </ArrowTooltip>
        </div>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles)(Tooltips);