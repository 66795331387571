// this file processes the data we receive from php.
import b64 from './b64';

let data;

const defaultData = () => {
  let res = JSON.parse(atob(b64));
  // add test variables, etc here.
  // res['debug'] = 1;
  res['logger'] = 1;
  res['practice'] = {
    "guid":"{73649446-430C-AAAA-A861-18EC9CA58888}",
    "name":"Mint Dental Spa On Quail ",
    "address":"1500 Quail Ste 440 Newport Beach CA 92660",
    "email":"sdq@yapi.me",
    "web":"yapiapp.com",
    "twphone":"+17122012353",
    "phone":"9492745904",
    "utcoffset":"-8",
    "twtoken":"6d63586d70cf0d8bc47d3cc905a86055",
    "twaccount":"AC67df18d440261d77499ca787f247cf2d",
    "profileid":"1mk51XeQiWsPEzI7a",
    "pmsdatabase":"Dentrix 16.4(16.4.100.1)",
    "google_place_id":"ChIJ956hn_OIwoARfpJNVE5ZQIk",
    "fb_place_id":"178823202153300",
    "yelp_place_id":"dentistry-for-kids-and-adults-canyon-country",
    "hg_place_id":""
  };
  // res['practice_web'] = 'http://www.google.com';
  // res['token'] = 'ydjNfFbcUDdoe0vaf8sBpnzoJJnhDSi4mKPGANRbc%2BVB6sViccHhaacDPypKgVadOAhPXonnqT%2Bj13hL0z8V%2FPKxwlllkzKc5SG28b0DNYvSmCDFdlTlFEN5kMtITqByrFo%2FAbiUQH0%2FiuakwbP8GA%2BNuhFSL5SmGcR2xQntKKds0Y0s2l9080SEDJ47LGC9uOfI0aU1iKKk9Snl6lgg7pyDr3AA3LKj%2Bs24%2BbPZ5BoMAdnfP93SEbOAqC0sHe03%2BHY%2FoF1x2JyakuzaOOqlbzprrU1NjZR7cGP5VWNWCI%2BNLfKqNsYtdoBbDZxpYzDsakZoRIiOIwCBiWYgboCV6MfMN0WGA0tvaz5exEyOPptykK2evy892LKNPscmcG2CwPm%2FNdEs1LLD0lClzvJYPkIyQ0HXhRVfvzgZQU%2FuZ0ZaRn3u18GbsWTUNyXKVoq1RCLimXoIQfLynvBAhIuyEVN9X%2F0KL%2Bg6urolTLpZDC%2BYmywUmXUOBkfk7KXbrfIEJN02ETiUmjjeh7ecIYO84Lk3mLAMeL%2FLWuJSGBtHMQ7aO6Sj7KGhsqSiPgjvV16dKEYB1QCwq9%2BNM59r0OryitSesZR0z31rZs4Ml9kay1EoZkUvwzWAwJOwJ1eS7iODTYwiotJkHfTNKet1b%2F44s46L%2FkVxSRqXBPvdz%2BovEnKRNxQpJ2uZiNN7vg3Wh8OIbQcdBwdFlaAUjOegLmpAvSM%2FT4DI2812fX3zIzKkVnbxpsj%2FeD1ANIRh7Eh9PguWIj3RmOq5NsYH8aICCtTslFOBuTZC%2BBJcGYLPRDq%2FatzhnKI%2B8wWtXgdnjLfcRdPH'
  // res['practice_address'] = '123 fake street';
  res['google_place_id'] = 'fake';
  // res['allow_language'] = 1;
  // res['latest_login'] = {
  //   guid: 'something',
  //   login_type: 1,
  //   username: 'Goo Gal',
  // };
  // res['error'] = 1;
  // res['error_title'] = 'Test Error';
  // res['error_subtitle'] = 'Subtest Error';
  // res['error_message'] = 'Error!';
  // res['login_error'] = 1;
  // res['login_error_retry'] = 1;
  // res['latest_login'] = null;
  // res['practice'] = null;
  res['dark_mode'] = 1
  res.login.apple_enabled = 1;
  return res;
}

if (window.antibes_object) {
  if (typeof window.antibes_object === 'string') {
    data = JSON.parse(atob(window.antibes_object));
  } else {
    data = defaultData();
  }
} else {
  data = defaultData();
}

export default data;