import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import withWidth from '@material-ui/core/withWidth'

function Responsive(props) {
  const { width, smaller, small, medium, large } = props
  // console.log('this.props', props)
  let component

  switch (width) {
    case 'xs':
      component = smaller ? smaller : small ? small : medium ? medium : large
      break
    case 'sm':
      component = medium ? medium : small ? small : large
      break
    case 'md':
      component = medium ? medium : small ? small : large
      break
    // case 'md':
    //   component = medium ? medium : large ? large : small
    //   break
    case 'lg':
      component = large ? large : medium ? medium : small
      break
    case 'xl':
      component = large ? large : medium ? medium : small
      break
    default:
      throw new Error(`Unknown width ${width}`)
  }
  return <Fragment>{component}</Fragment>
}

Responsive.propTypes = {
  width: PropTypes.string.isRequired,
}

export default withWidth()(Responsive)
