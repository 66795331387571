import React, {
  Fragment,
} from 'react';

import {
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import defaultLogo from '../../../../static/pngs/defaultLogo.png';

import ActionButton from '../ActionButton/ActionButton';

const styles = theme =>({
  blueText: {
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  changeAccountS: {
    marginTop: '40px',
  },
  changeAccountM: {
    marginTop: '56px',
  },
  changeAccountL: {
    marginTop: '56px',
  },
  imageGridS: {
    marginTop: '114px',
  },
  imageGridM: {

  },
  imageGridL: {

  },
  imageS: {
    height: '130px',
    width: '130px',
  },
  imageM: {
    height: '172px',
    width: '172px',
  },
  imageL: {
    height: '172px',
    width: '172px',
  },
  patientGridS: {
    marginBottom: '68px',
  },
  patientGridM: {
    marginBottom: '90px',
  },
  patientGridL: {
    marginBottom: '40px',
  },
  welcomeS: {
    marginTop: '24px',
    marginBottom: '35px',
  },
  welcomeM: {
    marginTop: '48px',
    marginBottom: '80px',
  },
  welcomeL: {
    marginTop: '40px',
    marginBottom: '56px',
  },
});

const LoggedIn = (props) => {
  const {
    classes,
    logo,
    patientName,
    practiceName,
    size,
    type,
    words,
    actionButtonFunction,
    forgotFunction,
  } = props;
  return(
    <Fragment>
      <Grid
        item
        className = {
          size === 'l' ? classes.imageGridL
          : size === 'm' ? classes.imageGridM
          : classes.imageGridS
        }
      >
        <img
          alt = {practiceName}
          src = {logo ? logo : defaultLogo}
          className = {
            size === 'l' ? classes.imageL
            : size === 'm' ? classes.imageM
            : classes.imageS
          }
        />
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.welcomeL 
          : size === 'm' ? classes.welcomeM
          : classes.welcomeS
        }
      >
        <Typography
          align='center'
          variant={
            size === 'l' ? 'body1'
            : 'h2'
          }
        >
          {words.welcome}
        </Typography>
        <Typography
          align = 'center'
          variant = {
            size === 's' ? 'subtitle1'
            : 'body2'
          }
        >
          {practiceName}
        </Typography>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.patientGridL
          : size === 'm' ? classes.patientGridM
          : classes.patientGridS
        }
      >
        <Typography
          variant = {
            size === 's' ? 'subtitle1'
            : 'subtitle1'
          }
        >
          {patientName}
        </Typography>
        <Typography
          variant = {
            size === 'l' ? 'body1'
            : size === 'm' ? 'h2'
            : 'h2'
          }
        >
          {words.signedIn} {type}
        </Typography>
      </Grid>
      <ActionButton
        disabled = {false}
        size = {size}
        type = {type}
        words = {words.actionButton + type}
        actionButtonFunction = {actionButtonFunction}
      />
      <Grid
        item
        className = {
          size === 'l' ? classes.changeAccountL
          : size === 'm' ? classes.changeAccountM
          : classes.changeAccountS
        }
      >
        <Typography
          variant = 'h4'
        >
          {words.notYou}
          <span
            className = {classes.blueText}
            onClick = {forgotFunction('account')}
          >
            {words.anotherAccount}
          </span>
        </Typography>
      </Grid>
    </Fragment>
  );
}

export default withStyles(styles)(LoggedIn);