import { createMuiTheme } from '@material-ui/core/styles';

// const color = '#424242';
const color = 'rgb(0, 0, 0)';
const fonts = ['Roboto Condensed', 'Source Sans Pro', 'sans-serif'].join(',');

const themeLarge = createMuiTheme({
  typography: {
    useNextVariants: true,
    color: color,
    fontFamily: fonts,
    body1: { //h4
      color: color,
      fontFamily: fonts,
      fontWeight: 'normal',
      fontSize: '24px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    body2: { //h2
      color: color,
      fontFamily: fonts,
      fontSize: '34px',
      fontWeight: 'bold',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    button: {
      fontFamily: fonts,
      lineHeight: 1,
      textTransform: 'none',
      letterSpacing: '0px',
    },
    h1: { //h1
      color: color,
      fontFamily: fonts,
      fontWeight: 'bold',
      fontSize: '52px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h2: { //h5
      color: color,
      fontFamily: fonts,
      fontWeight: 'bold',
      fontSize: '20px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h3: { //h6
      color: color,
      fontFamily: fonts,
      fontWeight: 'bold',
      fontSize: '18px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h4: { //h8
      color: color,
      fontFamily: fonts,
      fontWeight: 'bold',
      fontSize: '16px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h5: { //h7
      color: color,
      fontFamily: fonts,
      fontWeight: 'normal',
      fontSize: '18px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h6: { //h9
      color: color,
      fontFamily: fonts,
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    subtitle1: { //h3
      color: color,
      fontFamily: fonts,
      fontSize: '28px',
      fontWeight: 'bold',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    subtitle2: { //h10
      color: color,
      fontFamily: fonts,
      fontSize: '14px',
      fontWeight: 'normal',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    caption: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: color,
    }
  },
  palette: {
    primary: 
    {
      main: '#F7B500', // gold for switches
    },
    secondary:
    {
      main: '#006FE6', // blue for text
    },
    error: {
      bell: '#FF3434',
      main: '#EB0000', // red for errors
      main2: 'rgba(255, 54, 54, 0.25)', // lighter red for lighter errors
    },
    action: {
      disabledBackground: '#ECECEC',
      disabled: '#ffffff',
    },
    background: {
      bottom: '196, 196, 196',
      default: '#ffffff',
      notification: '255, 193, 136',
      shading: '229, 229, 229,',
      tooltip: '#E5E5E5',
      verified: 'rgba(0, 0, 0, 0.8)',
      zeroes: '0, 0, 0'
    },
    border: {
      confirm: 'rgba(62, 174, 11, 0.25)',
      tooltip: 'transparent',
    },
    info: {
      main: '#C4C4C4',
    },
    text: {
      button: '#000000',
      darkOrange: '#F7931E', // dark orange for sidenav title
      green: '#2E8406', // green for confirm
      primary: '#000000', // black
      secondary: '#595959',  // gray
    },
    grey: {
      300: '#ffffff'
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
          WebkitTextFillColor:'white'
        },
      }
    },
  },
})
export default themeLarge;