import React from 'react';
import {
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText,
  Slide,
  Typography, 
  IconButton,
  withStyles } from '@material-ui/core';

// import CloseIcon from '@material-ui/icons/CancelRounded';
import downloadImage from '../../../static/svgs/Download.svg';
import exitButton from '../../../static/svgs/ExitButton.svg';

const styles = theme => ({
  dialogS: {
    borderRadius: '20px',
  },
  dialogM: {
    maxWidth: '664px',
    borderRadius: '20px',
    margin: 'auto',
  },
  dialogL: {
    maxWidth: '664px',
    borderRadius: '20px',
    margin: 'auto',
  },
  dialogRootS: {
    padding: '0px 16px 0px',
  },
  dialogRootM: {
    padding: '0px 32px 0px',
  },
  dialogRootL: {
    padding: '0px 32px 0px',
  },
  downloadS: {
    padding: '0px 16px 0px',
  },
  downloadM: {
    padding: '0px 32px 0px',
  },
  downloadL: {
    padding: '0px 32px 0px',
  },
  downloadImageS: {
    height: '13px',
    width: '13px',
    marginRight: '8px',
  },
  downloadImageM: {
    height: '13px',
    width: '13px',
    marginRight: '8px',
  },
  downloadImageL: {
    height: '13px',
    width: '13px',
    marginRight: '8px',
  },
  downloadRoot: {
    flex: 'none',
  },
  downloadText: {
    color: theme.palette.secondary.main,
    width: '30%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconS: {
    boxShadow: 'none',
    borderRadius: '50px',
    height: '22px',
    width: '22px',
  },
  iconM: {
    boxShadow: 'none',
    borderRadius: '50px',
    height: '31px',
    width: '31px',
  },
  iconL: {
    boxShadow: 'none',
    borderRadius: '50px',
    height: '31px',
    width: '31px',
  },
  iconButtonS: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    padding: '0px',
  },
  iconButtonM: {
    position: 'absolute',
    right: '32px',
    top: '24px',
    padding: '0px',
  },
  iconButtonL: {
    position: 'absolute',
    right: '32px',
    top: '24px',
    padding: '0px',
  },
  modalText: {
    whiteSpace: 'pre-line',
  },
  rootS: {
    margin: '0px',
    maxHeight: '100%',
    // maxWidth: '100%',
    width: '100vw',
    height: '100vh',
    overflowY: 'auto',
  },
  rootM: {
    margin: '0px',
    maxWidth: '664px',
    maxHeight: '693px',
    overflowY: 'auto',
    borderRadius: '20px',
  },
  rootL: {
    margin: '0px',
    maxWidth: '664px',
    maxHeight: '693px',
    overflowY: 'auto',
    borderRadius: '20px',
  },
  titleRootS: {
    padding: '0px 16px 16px',
  },
  titleRootM: {
    padding: '0px 32px 16px',
  },
  titleRootL: {
    padding: '0px 32px 16px',
  },
  titleTextS: {
    paddingTop: '16px',
  },
  titleTextM: {
    paddingTop: '24px',
  },
  titleTextL: {
    paddingTop: '25px',
  },
});

const TextModal = (props) => {
  const {
    classes,
    modalOpen,
    size,
    title,
    text,
    downloadFunction,
    handleModalClose,
  } = props;
  if (size === 's') {
    return(
      <Dialog
        aria-labelledby = 'alert-dialog-title'
        aria-describedby = 'alert-dialog-description'
        open = {modalOpen}
        onClose = {handleModalClose}
        className = {classes.dialogS}
        PaperProps={{
          classes: {
            root: classes.rootS,
          },
        }}
      >
        <Slide
          direction = 'up'
          in = {modalOpen}
          timeout = {350}
        >
          <div>
            <DialogTitle
              disableTypography
              className = {classes.titleRootS}
            >
              <Typography
                align = 'left'
                variant = 'subtitle1'
                className = {classes.titleTextS}
              >
                {title}
              </Typography>
              <IconButton
                aria-label = 'Close'
                className = {classes.iconButtonS}
                onClick={handleModalClose}
              >
                <img
                  alt = 'Close'
                  src = {exitButton}
                  className = {classes.iconS}
                />
              </IconButton>
            </DialogTitle>
            <DialogContent
              classes = {{
                root: classes.downloadRoot,
              }}
              className = {classes.downloadS}
            >
              <DialogContentText
                id = 'download-text'
                variant = 'h4'
                // color = 'textPrimary'
                className = {classes.downloadText}
                onClick = {downloadFunction}
              >
                <img
                  src = {downloadImage}
                  alt = 'download'
                  className = {classes.downloadImageS}
                />
                Download
              </DialogContentText>
            </DialogContent>
            <DialogContent
              className = {classes.dialogRootS}
            >
              <DialogContentText
                id = 'alert-dialog-description'
                variant = 'h6'
                color = 'textPrimary'
                className = {classes.modalText}
                align = 'justify'
              >
                {text}
              </DialogContentText>
            </DialogContent>
          </div>
        </Slide>
      </Dialog>
    );
  } else {
    return(
      <Dialog
        aria-labelledby = 'alert-dialog-title'
        aria-describedby = 'alert-dialog-description'
        open = {modalOpen}
        onClose = {handleModalClose}
        className = {
          size === 'l' ? classes.dialogL 
          : classes.dialogM 
        }
        PaperProps={{
          classes: {
            root: size === 'l' ? classes.rootL 
            : classes.rootM,
          }
        }}
      >
        <DialogTitle
          disableTypography
          className = {
            size === 'l' ? classes.titleRootL 
            : classes.titleRootM
          }
        >
          <Typography
            align = 'left'
            variant = 'subtitle1'
            className = {
              size === 'l' ? classes.titleTextL 
              : classes.titleTextM
            }
          >
            {title}
          </Typography>
          <IconButton
            aria-label = 'Close'
            className = {
              size === 'l' ? classes.iconButtonL 
              : classes.iconButtonM
            }
            onClick={handleModalClose}
          >
            <img
              alt = 'Close'
              src = {exitButton}
              className = {
                size === 'l' ? classes.iconL
                : classes.iconM
              }
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          classes = {{
            root: classes.downloadRoot,
          }}
          className = {
            size === 'l' ? classes.downloadL
            : classes.downloadM
          }
        >
          <DialogContentText
            id = 'download-text'
            variant = {
              size === 'l' ? 'h3'
              : 'h4' 
            }
            // color = 'textPrimary'
            className = {classes.downloadText}
            onClick = {downloadFunction}
          >
            <img
              src = {downloadImage}
              alt = 'download'
              className = {
                size === 'l' ? classes.downloadImageL
                : classes.downloadImageM
              }
            />
            Download
          </DialogContentText>
        </DialogContent>
        <DialogContent
          className = {
            size === 'l' ? classes.dialogRootL 
            : classes.dialogRootM
          }
        >
          <DialogContentText
            id = 'alert-dialog-description'
            variant = {
              size === 'm' ? 'subtitle2'
              : 'h6'
            }
            color = 'textPrimary'
            className = {classes.modalText}
            align = 'justify'
          >
            {text}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
};

export default withStyles(styles)(TextModal);