import React, {
  Component,
  // Fragment,
} from 'react';

import {
  Fade,
  Grid,
  Slide,
  withStyles,
} from '@material-ui/core';

// import moment from 'moment';

import ContactUs from '../Helpers/ContactUs/ContactUs';
import Error from './Error/Error';
import Forgot from './Forgot/Forgot';
import TextModal from '../Helpers/TextModal/TextModal';
import ErrorModal from '../Helpers/ErrorModal/ErrorModal';
import Login from './Login/Login.js';
import PhoneVerify from './PhoneVerify/PhoneVerify';
import TemplateView from '../Helpers/TemplateView/TemplateView';
import TopBar from '../Helpers/TopBar/TopBar';

import '../../logic/yapicallback/callback';
import inputCheck from '../../logic/inputCheck';
// import errorCheck from '../../logic/errorCheck';

import data from '../../static/data';
import kotoba from '../../static/kotoba';

//Retrofit for IE11
import 'react-app-polyfill/ie11';

//smoothscroll fix for chrome v 49.0.2623
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

let logger = 1;

const styles = theme => ({
  grid: {
    height: '100vh',
  },
  gridM: {
    width: '100%',
  },
  gridS: {
    // position: 'fixed',
    // bottom: 0,
    // left: 0,
    // right: 0,
    // top: 0,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
  },
  scroll: {
    height: '100%',
    margin: 0,
  }
});

class Main extends Component {
  state = {
    contactUs: false, // flag for if displaying contact us view or not
    darkFlag: false, // flag for if dark mode is enabled
    direction: 'left', // flag for slide transition direction
    emailValue: '', // string for storing users email in case of forgotten login / password
    error: null, // object that will handle our errors, if they happen
    errorModalOpen: false, // for if the error modal is open or not
    errorModalText: '',
    errorModalTitle: 'Error',
    forgot: '', // string for if the user has forgotten password or username
    language: '', // string for which language the user clicks on
    languageChosen: false, // flag for if the user has chosen language
    latestLogin: {}, // object to take latest login object from antibes_object
    login: {}, // object to take login object from antibes_object
    // logoURL: '', // string for the url of the practice's logo, if we have it
    modalText: '', // string for the text for modal
    modalTitle: '', // string for modal title
    passwordValue: '', // string for user password, if allowed
    phoneCheck: '', // string for the 6 digit code sent to the user's phone
    practice: {}, // object to hold practice information
    // practiceName: 'Patient Portal', //stores practice name
    rememberUserToggle: false,
    resendAttempt: 0, // number value for how many times we've sent a code to phone
    showPassword: true,
    textModalOpen: false, // flag for if the text modal is open (terms and privacy policy)
    transitioning: true, // flag for if we are displaying a transition animation
    userPhone: '', // string for users last four digits of phone number
    userValue: '', // string for desired username
    v7Token: '', // string for token received from api to use in api
    v7Try: 0, // int for how many tries on v7
    verified: false, // flag that will be flipped after the user has successfully entered the code from phone
    verify: false, // flag that will be flipped if the user needs to be verified
    verifyError: false, // flag that will tell us if the verify modal has errored and should animate
    words: kotoba['eng-us'], // object that will hold all of the text for the app
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    logger && console.log('data:', data);
    const dark = parseInt(data.dark_mode);
    logger && console.log('dark mode flag found', dark);
    if (dark === 0 || !dark) {
      if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          logger && console.log('dark mode preference found');
          this.setState({
            // dark,
            darkFlag: true,
          });
          this.props.darkTime(true);
          document.body.style.backgroundColor = '#121212';
        }
      }
    } else if (dark === 1) {
      this.setState({
        // dark,
        darkFlag: false,
      });
      this.props.darkTime(false);
      document.body.style.backgroundColor = '#ffffff';
    } else if (dark === 2) {
      this.setState({
        // dark,
        darkFlag: true,
      });
      this.props.darkTime(true);
      document.body.style.backgroundColor = '#121212';
    }
    if (data.error) {
      logger && console.log('We have error!');
      const error = {
        text: data.error_message,
        title: data.error_title,
        subtitle: data.error_subtitle,
        // type: data.error_type,
      }
      if (data.practice) {
        this.setState({
          error,
          practice: data.practice,
        });
      } else {
        this.setState({
          error,
        });
      }
      return;
    }
    // if (data.language) {
    //   // if we have language, set it
    //   const lang = data.language;
    //   this.setState({
    //     words: kotoba[lang],
    //   });
    // } else {
      logger && console.log('Default to English'); // set to english to start because was causing loading issues
      // this.setState({
      //   words: kotoba['eng-us'],
      // });
    // }
    // if (data.error) {
    //   // error handling
    //   const error = data.error;
    //   this.setState({
    //     error,
    //   });
    // }
    if (data.login_error) {
      logger && console.log(data.login_error);
      // const error = data.login_error;
      let error = {
        title: data.error_message ? data.error_message : 'Login Error',
        // subtitle: data.error_subtitle ? data.error_subtitle : 'Placeholder Text',
        text: data.login_error_message ? data.login_error_message : 'There Was A Login Error',
      }
      if (parseInt(data.login_error_retry) === 1) {
        error['loginRetry'] = 1;
      }
      this.setState({
        error,
      });
    }
    if (data.allow_language) {
      if (parseInt(data.allow_language) === 1) {
        this.setState({
          languageChosen: false,
        });
      } else {
        this.setState({
          languageChosen: true,
          language: 'eng-us',
        });
      };
    } else {
      this.setState({
        languageChosen: true,
        language: 'eng-us',
      });
    }
    if (data.latest_login) {
      this.setState({
        latestLogin: data.latest_login,
      });
    }
    if (data.login) {
      this.setState({
        login: data.login,
      });
    }
    if (data.practice) {
      this.setState({
        practice: data.practice,
        // logoURL: data.logo_url,
      });
    }
  }

  actionButtonFunction = type => {
    // Function for the call to action buttons
    logger && console.log('ACTION BUTTON', type);
    if (type === 'signInFresh') {
      // login using fresh username and password
      if (typeof window.v2_verify6 === 'function') {
        logger && console.log('v2_verify6');
        const {
          userValue,
          passwordValue,
          rememberUserToggle,
        } = this.state;
        const saveUserId = rememberUserToggle ? 1 : 0
        window.v2_verify6(data.token, data.guid, userValue, passwordValue, saveUserId)
          .then(res => {
            logger && console.log('Return from verify6:', res);
            if (res.url) {
              if (res.url.toUpperCase().substring(0, 8) === 'HTTPS://') {
                window.location = res.url;
              }
            } else if (res.sfa) {
              logger && console.log('Taking to 2-Factor Authentication');
              this.setState({
                v7Token: res.sfa,
                verify: true,
                userPhone: res.mobile,
              });
            }
            if (res.error) {
              console.error('Res Error Placeholder');
            }
          })
      }
    } else if (type === 'signInKnown') {
      // login using username from previous login
      if (typeof window.v2_verify6 === 'function') {
        logger && console.log('v2_verify6');
        const {
          latestLogin,
          passwordValue,
          rememberUserToggle,
        } = this.state;
        const saveUserId = rememberUserToggle ? 1 : 0
        window.v2_verify6(data.token, data.guid, latestLogin.username, passwordValue, saveUserId)
          .then(res => {
            console.log('Return from verify6:', res);
            if (res.url) {
              if (res.url.toUpperCase().substring(0, 8) === 'HTTPS://') {
                window.location = res.url;
              }
            } else if (res.sfa) {
              logger && console.log('Taking to 2-Factor Authentication');
              this.setState({
                direction: 'left',
                transitioning: false,
                v7Token: res.sfa,
                verify: true,
                userPhone: res.mobile,
                userValue: latestLogin.username,
              });
              this.resetTransition();
            }
            if (res.error) {
              console.error('Res Error Placeholder');
            }
          })
      }
    } else if (type === 'verify') {
      // send to verify phone code
      if (typeof window.v2_verify7 === 'function') {
        logger && console.log('V7 API found');
        const {
          phoneCheck,
          userValue,
          v7Token,
        } = this.state;
        logger && console.log('phoneCheck, userValue, v7Token:');
        logger && console.log(phoneCheck, ',', userValue, ',', v7Token);
        window.v2_verify7(data.token, data.guid, userValue, phoneCheck, v7Token)
          .then(res => {
            logger && console.log('v7 res:', res);
            if (res.url) {
              // success!
              this.setState({
                verified: true,
              });
              if (res.url.toUpperCase().substring(0, 8) === 'HTTPS://') {
                setTimeout(() => {
                  window.location = res.url;
                  // this.setState({
                  //   verified: false,
                  // });
                }, 1200);
              }
            } else if (res.error) {
              const {
                v7Try,
              } = this.state;
              const newTry = v7Try + 1;
              if (newTry >= 3) {
                const error = {
                  abort: 1,
                  text: 'Too Many Attempts',
                  severity: 1,
                  subtitle: 'Error',
                  title: 'Too Many Attempts',
                };
                this.setState({
                  error: error,
                });
              } else {
                this.setState({
                  // errorModalOpen: true,
                  // errorModalTitle: 'Wrong Code',
                  // errorModalText: 'Please enter the correct code',
                  verifyError: true,
                  v7Try: newTry,
                });
              }
            }
          });
      }
    } else if (type === 'forgotPassword') {
      // password / username forgotten logic
      logger && console.log('Forgot Password');
      if (typeof window.v2_reuspa === 'function') {
        const {
          emailValue,
          words,
         } = this.state;
        window.v2_reuspa(data.token, data.guid, emailValue)
          .then(res => {
            logger && console.log(res);
            if (res[0]) {
              if (res[0].classname === 'ok') {
                const errorModalText = words.modal.password;
                this.setState({
                  errorModalText,
                  emailValue: '',
                  errorModalOpen: true,
                  errorModalTitle: '',
                  forgot: '',
                });
              }
            }
          })
          .catch(err => {
            logger && console.warn('error:', err);
          })
      }
      // this.setState({
      //   forgot: '',
      // });
    } else if (type === 'forgotUsername') {
      // username forgotten logic
      logger && console.log('Forgot Username');
      if (typeof window.v2_reusna === 'function') {
        const {
          emailValue,
          words,
         } = this.state;
        window.v2_reusna(data.token, data.guid, emailValue)
          .then(res => {
            logger && console.log(res);
            if (res.ok) {
              if (parseInt(res.ok) === 1) {
                const errorModalText = words.modal.username;
                this.setState({
                  errorModalText,
                  errorModalOpen: true,
                  errorModalTitle: '',
                  forgot: '',
                });
              }
            }
          })
          .catch(err => {
            logger && console.warn('error:', err);
          })
      }
      // this.setState({
      //   forgot: '',
      // });
    } else if (type === 'Google') {
      // good to go with google?
    } else if (type === 'Facebook') {
      // good to go with facebook?
    } else if (type === 'Apple') {
      // good to go with apple?
    }
  }

  backFunction = () => {
    // function to reset from being in the forgot username / password state
    this.setState({
      direction: 'right',
      forgot: '',
      transitioning: false,
    });
    setTimeout(() => {
      this.resetTransition('back');
    }, 100);
  }

  buttonFunction = type => {
    // Handles button presses on signupOne
    logger && console.log('buttonFunction', type);
    const {
      login,
    } = this.state;
    if (type === 'a') {
      // In the case of apple, we do nothing currently:
      if (login) {
        if (login.apple_url) {
          logger && console.log('Redirecting, please wait...');
          window.location = login.apple_url;
        } else {
          logger && console.error('No apple_url supplied');
        }
      }
    }
    if (type === 'f') {
      // In the case of facebook, redirect to url from received data:
      if (login) {
        if (login.facebook_url) {
          logger && console.log('Redirecting, please wait...');
          window.location = login.facebook_url;
        } else {
          logger && console.error('No facebook_url supplied');
        }
      }
    }
    if (type === 'g') {
      // In the case of google, redirect to url from received data:
      if (login) {
        if (login.google_url) {
          logger && console.log('Redirecting, please wait...');
          window.location = login.google_url;
        }
      }
    }
  }

  contactFunction = () => {
    // logger && console.log('Contact Us Logic Placeholder');
    this.setState({
      contactUs: true,
    });
  }

  forgotFunction = type => event => {
    // Function to activate the forgot username / password screens
    if (type === 'username') {
      logger && console.log('Forgot Username');
      this.setState({
        direction: 'left',
        forgot: 'Username',
        transitioning: false,
      });
      setTimeout(() => {
        this.resetTransition();
      }, 100);
    }
    if (type === 'password') {
      logger && console.log('Forgot Password');
      this.setState({
        direction: 'left',
        forgot: 'Password',
        transitioning: false,
      });
      setTimeout(() => {
        this.resetTransition();
      }, 100);
    }
    if (type === 'account') {
      logger && console.log('Switching Account');
      this.setState({
        direction: 'left',
        latestLogin: {
          guid: '',
          login_type: 0,
          username: '',
        },
        transitioning: false,
        userValue: '',
        passwordValue: '',
      });
      setTimeout(() => {
        this.resetTransition();
      }, 100);
    }
  }

  goBackFunction = () => {
    logger && console.log('Retrying from Error');
    this.setState({
      error: null,
    });
  }

  handleErrorModalClose = () => {
    logger && console.log('Closing error modal');
    this.setState({
      errorModalOpen: false,
      errorModalText: '',
      errorModalTitle: '',
    });
  }

  handleModalClose = () => {
    logger && console.log('Closing modal');
    this.setState({
      textModalOpen: false,
      modalText: '',
      modalTitle: '',
    });
  }

  handlePreventDefault = (event) => {
    event.preventDefault();
  }

  handleToggle = name => {
    const foo = this.state[name];
    logger && console.log(name, foo);
    this.setState({
      [name]: !foo,
    })
  }

  inputFunction = name => event => {
    // Function for the inputs on signupTwo
    const value = event.target.value;
    // logger && console.log(name, value);
    const test = inputCheck(name, value);
    if (test) {
      if (name === 'phoneCheck') {
        this.setState({
          [name]: value,
          verifyError: false,
        });
      } else {
        this.setState({
          [name]: value,
        });
      }
    }
  }

  languageFunction = name => {
    // Determines which language the user would like to use
    logger && console.log('Chosen language:', name);
    this.setState({
      language: name,
    });
  }
  
  languageButtonFunction = () => {
    // let us know the user has chosen a language
    logger && console.log('Clicked button');
    const {
      language,
    } = this.state;
    this.setState({
      words: kotoba[language],
      languageChosen: true,
    });
  }

  modalFunction = name => event => {
    logger && console.log('Modal Function.  Name:', name, 'event:', event);
    const { 
      words,
    } = this.state;
    // console.log(words.modals);
    if (name === 'tos') {
      this.setState({
        textModalOpen: true,
        modalTitle: words.modals.tocTitle,
        modalText: words.modals.tocText,
      });
    } else if (name === 'privacy') {
      this.setState({
        textModalOpen: true,
        modalTitle: words.modals.privacyTitle,
        modalText: words.modals.privacyText,
      });
    } else if (name === 'signIn') {
      logger && console.log('Sign In redirect logic here');
    } else {
      logger && console.log('How did you get here?');
    }
  }

  resendVerifyFunction = () => {
    const {
      resendAttempt,
    } = this.state;
    const newResend = resendAttempt + 1;
    if (newResend < 3) {
      this.setState({
        resendAttempt: newResend,
        phoneCheck: '',
      });
      this.sendVerifyOne();
    } else {
      logger && console.log('Too many attempts placeholder');
    }
  }

  resetTransition = (arg) => {
    // return transition variable to false
    if (arg) {
      if (arg === 'back') {
        this.setState({
          transitioning: true,
        });
      }
    } else {
      this.setState({
        direction: 'left',
        transitioning: true,
      });
    }
  }

  sendVerifyOne = () => {
    if (typeof window.v2_verify1 === 'function') {
      logger && console.log('Sending to verify 1');
      const phone = this.state.userPhone;
      window.v2_verify1(data.token, data.practice_guid, phone)
      .then(res => {
        logger && console.log(res);
        if (res.ok) {
          if (parseInt(res.ok) === 1) {
            logger && console.log('OK result, moving forward');
            const v1token = res.token;
            this.setState({
              v1token,
            });
          }
        } else if (res.error) {
          if (parseInt(res.error) === 1) {
            logger && console.log('error');
            const errorModalTitle = 'Error';
            const errorModalText = res.message;
            this.setState({
              errorModalOpen: true,
              errorModalText,
              errorModalTitle,
            });
            // const error = {
            //   text: res.message,
            //   abort: 0,
            //   severity: 0,
            //   title: 'Error',
            // }
            // this.setState({
            //   error,
            // });
          }
        }
      })
      .fail(err => {
        logger && console.log('Failure:', err);
      });
    }
  }

  sendVerifyTwo = () => {
    if (typeof window.v2_verify2 === 'function') {
      const {
        phoneCheck,
        v1token,
      } = this.state;
      logger && console.log('Sending to verify 2');
      const toV2 = phoneCheck + v1token;
      logger && console.log('Cookies:');
      logger && console.log(document.cookie);
      window.v2_verify2(data.token, data.practice_guid, toV2)
        .then(res => {
          logger && console.log('Got res:', res);
          if (res.ok) {
            if (parseInt(res.ok) === 1) {
              logger && console.log('OK result, moving forward');
              const token = res.verify4_token;
              this.sendVerifyFour(token);
            }
          } else if (res.error) {
            const {
              v2try,
              words,
            } = this.state;
            const newTry = v2try + 1;
            if (newTry >= 3) {
              const error = {
                text: words.error.attemptText,
                title: words.error.attemptTitle,
                abort: 1,
                severity: 1,
              };
              this.setState({
                error,
              });
              return;
            }
            const errorModalTitle = 'Error';
            const errorModalText = res.message;
            this.setState({
              errorModalOpen: true,
              v2try: newTry,
              errorModalText,
              errorModalTitle,
            });
            // const error = {
            //   text: res.message,
            //   abort: 0,
            //   severity: 0,
            //   title: 'Error',
            // }
            // this.setState({
            //   error,
            // });
          }
        })
        .fail(err => {
          logger && console.log('Failure:', err);
        });
    }
  }

  userInputFunction = name => event => {
    // function for user name / password input
    const value = event.target.value;
    // logger && console.log(name, value);
    if (name === 'passwordValue') {
      let passFlag = {
        long: false,
        number: false,
        upper: false,
      };
      if (value.length === 0) {
        this.setState({
          [name]: value,
          showPassword: false,
          passFlag,
        });
      } else {
        if (value.length >= 6) {
          passFlag['long'] = true;
        }
        const numTest = /\d/.test(value);
        if (numTest) {
          passFlag['number'] = true;
        }
        const lowerTest = value.toLowerCase();
        const upperTest = lowerTest !== value;
        if (upperTest) {
          passFlag['upper'] = true;
        }
        this.setState({
          [name]: value,
          passFlag,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  verifyPhoneButtonFunction = () => {
    logger && console.log('preparing to verify');
    this.sendVerifyTwo();
  }

  render() {
    const {
      contactUs,
      darkFlag,
      direction,
      emailValue,
      error,
      errorModalOpen,
      errorModalText,
      errorModalTitle,
      forgot,
      // language,
      // languageChosen,
      latestLogin,
      login,
      // logoURL,
      modalText,
      modalTitle,
      passwordValue,
      practice,
      // practiceName,
      rememberUserToggle,
      showPassword,
      textModalOpen,
      transitioning,
      userPhone,
      userValue,
      verify,
      verifyError,
      words,
    } = this.state;
    const {
      classes,
      size,
    } = this.props;
    // console.log(size);
    // logger && console.log('words:', words);
    // logger && console.log('size:', size);
    // logger && console.log('user:', user);
    if (contactUs) {
      logger && console.log('Contact Us Displaying');
      return(
        <Grid
          container
          alignItems = 'center'
          direction = 'column'
          justify = 'center'
          className = {
            size === 's' ? classes.gridS
            : size === 'm' ? classes.gridM
            : classes.grid
        }
        >
          <TopBar
            data = {practice}
            darkFlag = {darkFlag}
            error = {error}
            showMenu = {0}
            size = {size}
          />
          <Grid
            item
          >
            <ContactUs
              data = {practice}
              darkFlag = {darkFlag}
              size = {size}
            />
          </Grid>
        </Grid>
      )
    } else if (error) {
      logger && console.log('error:', error);
      return(
        <Error
          abort = {error.abort}
          darkFlag = {darkFlag}
          logger = {logger}
          loginRetry = {error.loginRetry}
          message = {error.text}
          nexturl = {error.nexturl}
          practice = {practice}
          severity = {error.severity}
          size = {size}
          subtitle = {error.subtitle}
          title = {error.title}
          type = {error.type}
          contactFunction = {this.contactFunction}
          goBackFunction = {this.goBackFunction}
        />
      );
    } else {
      if (data) {
        if (verify) {
          const {
            phoneCheck,
            resendAttempt,
            verified,
          } = this.state;
          // const error = errorCheck(user);
          if (size === 's') {
            return(
              <Slide
                direction = {direction}
                exit = {false}
                in = {transitioning}
              >
                <div
                  className = {classes.scroll}
                >
                  <Grid
                    container
                    alignItems = 'center'
                    direction = 'column'
                    justify = 'center'
                    className = {classes.gridS}
                  >
                    <TopBar
                      data = {practice}
                      darkFlag = {darkFlag}
                      error = {error}
                      showMenu = {1}
                      size = {size}
                    />
                    <PhoneVerify
                      // loginType = {loginType}
                      darkFlag = {darkFlag}
                      phoneCheck = {phoneCheck}
                      resendAttempt = {resendAttempt}
                      size = {size}
                      user_phone = {userPhone ? userPhone : '****'}
                      verified = {verified}
                      verifyError = {verifyError}
                      // username = {username}
                      words = {words.phoneVerify}
                      backFunction = {this.backFunction}
                      buttonFunction = {this.actionButtonFunction}
                      inputFunction = {this.inputFunction}
                      resendFunction = {this.resendVerifyFunction}
                    />
                  </Grid>
                  {errorModalOpen && (
                    <ErrorModal
                      buttonText = {words.error.accept}
                      darkFlag = {darkFlag}
                      size = {size}
                      modalOpen = {errorModalOpen}
                      handleModalClose = {this.handleErrorModalClose}
                      title = {errorModalTitle}
                      text = {errorModalText}
                    />
                  )}
                </div>
              </Slide>
            );
          } else {
            return(
              <Fade
                in = {transitioning}
                timeout = {200}
              >
                <div>
                  <Grid
                    container
                    alignItems = 'center'
                    direction = 'column'
                    justify = 'center'
                    className = {classes.grid}
                  >
                    <TopBar
                      data = {practice}
                      darkFlag = {darkFlag}
                      error = {error}
                      showMenu = {1}
                      size = {size}
                    />
                    <TemplateView
                      dark = {darkFlag}
                      inside = {(
                        <Grid
                          container
                          alignItems = 'center'
                          direction = 'column'
                          justify = 'center'
                          className = {classes.gridS}
                        >
                          <PhoneVerify
                            darkFlag = {darkFlag}
                            // loginType = {loginType}
                            phoneCheck = {phoneCheck}
                            resendAttempt = {resendAttempt}
                            size = {size}
                            user_phone = {userPhone ? userPhone : '****'}
                            verified = {verified}
                            verifyError = {verifyError}
                            // username = {username}
                            words = {words.phoneVerify}
                            backFunction = {this.backFunction}
                            buttonFunction = {this.actionButtonFunction}
                            inputFunction = {this.inputFunction}
                            resendFunction = {this.resendVerifyFunction}
                          />
                        </Grid>
                      )}
                      size = {size}
                      step = {1}
                      verified = {verified}
                    />
                  </Grid>
                  {errorModalOpen && (
                    <ErrorModal
                      buttonText = {words.error.accept}
                      darkFlag = {darkFlag}
                      size = {size}
                      modalOpen = {errorModalOpen}
                      handleModalClose = {this.handleErrorModalClose}
                      title = {errorModalTitle}
                      text = {errorModalText}
                    />
                  )}
                </div>
              </Fade>
            )
          }
        } else {
          if (forgot) {
            if (size === 's') {
              return(
                <Slide
                  direction = {direction}
                  exit = {false}
                  in = {transitioning}
                >
                  <div
                    className = {classes.scroll}
                  >
                    <Grid
                      container
                      alignItems = 'center'
                      direction = 'column'
                      justify = 'center'
                      className = {classes.gridS}
                    >
                        <TopBar
                          darkFlag = {darkFlag}
                          data = {practice}
                          error = {error}
                          showMenu = {1}
                          size = {size}
                        />
                        <Forgot
                          darkFlag = {darkFlag}
                          emailValue = {emailValue}
                          forgot = {forgot}
                          size = {size}
                          words = {words.forgot}
                          actionButtonFunction = {this.actionButtonFunction}
                          backFunction = {this.backFunction}
                          inputFunction = {this.inputFunction}
                        />
                    </Grid>
                    {textModalOpen && (
                      <TextModal
                        darkFlag = {darkFlag}
                        size = {size}
                        modalOpen = {textModalOpen}
                        handleModalClose = {this.handleModalClose}
                        title = {modalTitle}
                        text = {modalText}
                      />
                    )}
                    {errorModalOpen && (
                      <ErrorModal
                        buttonText = {words.error.accept}
                        darkFlag = {darkFlag}
                        size = {size}
                        modalOpen = {errorModalOpen}
                        handleModalClose = {this.handleErrorModalClose}
                        title = {errorModalTitle}
                        text = {errorModalText}
                      />
                    )}
                  </div>
                </Slide>
              );
            } else {
              return(
                <Fade
                  in = {transitioning}
                  timeout = {200}
                >
                  <div>
                    <Grid
                      container
                      alignItems = 'center'
                      direction = 'column'
                      justify = 'center'
                      className = {classes.grid}
                    >
                        <TopBar
                          darkFlag = {darkFlag}
                          data = {practice}
                          error = {error}
                          showMenu = {1}
                          size = {size}
                        />
                        <TemplateView
                          // create = {create}
                          dark = {darkFlag}
                          inside = {(
                            <Grid
                              container
                              alignItems = 'center'
                              direction = 'column'
                              justify = 'center'
                              className = {classes.gridS}
                            >
                              <Forgot
                                darkFlag = {darkFlag}
                                emailValue = {emailValue}
                                forgot = {forgot}
                                size = {size}
                                words = {words.forgot}
                                actionButtonFunction = {this.actionButtonFunction}
                                backFunction = {this.backFunction}
                                inputFunction = {this.inputFunction}
                              />
                            </Grid>
                          )}
                          size = {size}
                          // step = {step}
                        />
                    </Grid>
                    {textModalOpen && (
                      <TextModal
                        darkFlag = {darkFlag}
                        size = {size}
                        modalOpen = {textModalOpen}
                        handleModalClose = {this.handleModalClose}
                        title = {modalTitle}
                        text = {modalText}
                      />
                    )}
                    {errorModalOpen && (
                      <ErrorModal
                        buttonText = {words.error.accept}
                        darkFlag = {darkFlag}
                        size = {size}
                        modalOpen = {errorModalOpen}
                        handleModalClose = {this.handleErrorModalClose}
                        title = {errorModalTitle}
                        text = {errorModalText}
                      />
                    )}
                  </div>
                </Fade>
              );
            }
          } else {
            if (size === 's') {
              return(
                <Slide
                  direction = {direction}
                  exit = {false}
                  in = {transitioning}
                >
                  <div
                    className = {classes.scroll}
                  >
                    <Grid
                      container
                      alignItems = 'center'
                      direction = 'column'
                      justify = 'center'
                      className = {classes.gridS}
                    >
                        <TopBar
                          darkFlag = {darkFlag}
                          data = {practice}
                          error = {error}
                          showMenu = {1}
                          size = {size}
                        />
                        <Login
                          darkFlag = {darkFlag}
                          latestLogin = {latestLogin}
                          login = {login}
                          // logo = {logoURL}
                          passwordValue = {passwordValue}
                          practice = {practice}
                          rememberUserToggle = {rememberUserToggle}
                          showPassword = {showPassword}
                          size = {size}
                          userValue = {userValue}
                          words = {words.login}
                          actionButtonFunction = {this.actionButtonFunction}
                          buttonFunction = {this.buttonFunction}
                          forgotFunction = {this.forgotFunction}
                          handlePreventDefault = {this.handlePreventDefault}
                          handleToggle = {this.handleToggle}
                          userInputFunction = {this.inputFunction}
                        />
                    </Grid>
                    {textModalOpen && (
                      <TextModal
                        darkFlag = {darkFlag}
                        size = {size}
                        modalOpen = {textModalOpen}
                        handleModalClose = {this.handleModalClose}
                        title = {modalTitle}
                        text = {modalText}
                      />
                    )}
                    {errorModalOpen && (
                      <ErrorModal
                        buttonText = {words.error.accept}
                        darkFlag = {darkFlag}
                        size = {size}
                        modalOpen = {errorModalOpen}
                        handleModalClose = {this.handleErrorModalClose}
                        title = {errorModalTitle}
                        text = {errorModalText}
                      />
                    )}
                  </div>
                </Slide>
              );
            } else {
              return(
                <Fade
                  in = {transitioning}
                  timeout = {200}
                >
                  <div>
                    <Grid
                      container
                      alignItems = 'center'
                      direction = 'column'
                      justify = 'center'
                      className = {classes.grid}
                    >
                        <TopBar
                          darkFlag = {darkFlag}
                          data = {practice}
                          error = {error}
                          showMenu = {1}
                          size = {size}
                        />
                        <TemplateView
                          // create = {create}
                          dark = {darkFlag}
                          inside = {(
                            <Grid
                              container
                              alignItems = 'center'
                              direction = 'column'
                              justify = 'center'
                              className = {classes.gridS}
                            >
                              <Login
                                darkFlag = {darkFlag}
                                latestLogin = {latestLogin}
                                login = {login}
                                // logo = {logoURL}
                                passwordValue = {passwordValue}
                                practice = {practice}
                                rememberUserToggle = {rememberUserToggle}
                                showPassword = {showPassword}
                                size = {size}
                                userValue = {userValue}
                                words = {words.login}
                                actionButtonFunction = {this.actionButtonFunction}
                                buttonFunction = {this.buttonFunction}
                                forgotFunction = {this.forgotFunction}
                                handlePreventDefault = {this.handlePreventDefault}
                                handleToggle = {this.handleToggle}
                                userInputFunction = {this.inputFunction}
                              />
                            </Grid>
                          )}
                          size = {size}
                          // step = {step}
                        />
                    </Grid>
                    {textModalOpen && (
                      <TextModal
                        darkFlag = {darkFlag}
                        size = {size}
                        modalOpen = {textModalOpen}
                        handleModalClose = {this.handleModalClose}
                        title = {modalTitle}
                        text = {modalText}
                      />
                    )}
                    {errorModalOpen && (
                      <ErrorModal
                        buttonText = {words.error.accept}
                        darkFlag = {darkFlag}
                        size = {size}
                        modalOpen = {errorModalOpen}
                        handleModalClose = {this.handleErrorModalClose}
                        title = {errorModalTitle}
                        text = {errorModalText}
                      />
                    )}
                  </div>
                </Fade>
              );
            }
          }
        }
      }
    }
  }
}

export default withStyles(styles)(Main);