import React, {
  Fragment,
} from 'react';

import {
  Grid,
  Input,
  InputAdornment,
  Typography,
  withStyles,
} from '@material-ui/core';

import Tooltip from '../../../Helpers/Tooltip/Tooltip';

const styles = theme => ({
  centered: {
    textAlign: 'center',
  },
  disabled: {
    color: theme.palette.text.secondary,
  },
  inputLabelS: {

  },
  inputLabelM: {

  },
  inputLabelL: {
    // marginTop: '32px',
  },
  passwordInputS: {
    borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
    paddingLeft: '30px',
    marginTop: '11px',
  },
  passwordInputM: {
    borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
    paddingLeft: '30px',
    marginTop: '28px',
  },
  passwordInputL: {
    borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
    paddingLeft: '30px',
    marginTop: '18px',
  },
  passGridS: {
    maxWidth: '375px',
    // marginTop: '48px',
    marginBottom: '12px',
    padding: '0px 32px',
    width: '100%',
  },
  passGridM: {
    // marginTop: '40px',
    marginBottom: '40px',
    maxWidth: '442px',
    padding: '0px 32px',
    width: '100%',
  },
  passGridL: {
    // marginTop: '32px',
    marginBottom: '20px',
    maxWidth: '442px',
    padding: '0px 32px',
    width: '100%',
  },
  userInputS: {
    borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
    // paddingLeft: '30px',
    marginTop: '11px',
  },
  userInputM: {
    borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
    // paddingLeft: '30px',
    marginTop: '13px',
  },
  userInputL: {
    borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
    // paddingLeft: '30px',
    marginTop: '18px',
  },
  userGridS: {
    maxWidth: '375px',
    // marginTop: '20px',
    marginBottom: '27px',
    padding: '0px 32px',
    width: '100%',
  },
  userGridM: {
    // marginTop: '40px',
    marginBottom: '24px',
    maxWidth: '442px',
    padding: '0px 32px',
    width: '100%',
  },
  userGridL: {
    // marginTop: '20px',
    marginBottom: '20px',
    maxWidth: '442px',
    padding: '0px 32px',
    width: '100%',
  },
  visibilityS: {
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  visibilityM: {
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  visibilityL: {
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const UserInputs = (props) => {
  const {
    classes,
    darkFlag,
    passwordValue,
    size,
    showPassword,
    words,
    username,
    userValue,
    handlePreventDefault,
    handleToggle,
    userInputFunction,
  } = props;

  return(
    <Fragment>
      <Grid
        item
        className = {
          size === 'l' ? classes.inputLabelL
          : size === 'm' ? classes.inputLabelM
          : classes.inputLabelS
        }
      >
        <Typography
          variant = {
            size === 'l' ? 'h5'
            : size === 'm' ? 'h6'
            : 'h5'
          }
        >
          { words.userInput }
        </Typography>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.userGridL
          : size === 'm' ? classes.userGridM
          : classes.userGridS
        }
      >
        <Input
          disableUnderline
          fullWidth
          autoComplete = 'off'
          disabled = {username ? true : false}
          value = {username ? username : userValue}
          className = {
            size === 'l' ? classes.userInputL
            : size === 'm' ? classes.userInputM
            : classes.userInputS
          }
          classes = {{
            input: classes.centered,
            disabled: classes.disabled
          }}
          onChange = {userInputFunction('userValue')}
        />
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.inputLabelL
          : size === 'm' ? classes.inputLabelM
          : classes.inputLabelS
        }
      >
        <Grid
          container
          alignItems = 'center'
          justify = 'center'
          className = {classes.wide}
        >
          <Grid
            item
          >
            <Typography
              variant = {
                size === 'l' ? 'h5'
                : size === 'm' ? 'h6'
                : 'h5'
              }
              className = {classes.greyText}
            >
              { words.passwordInput }
            </Typography>
          </Grid>
          <Grid
            item
          >
            <Tooltip
              size = {size}
              text = {words.passwordTooltip}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.passGridL
          : size === 'm' ? classes.passGridM
          : classes.passGridS
        }
      >
        <Input
          disableUnderline
          fullWidth
          className = {
            size === 'l' ? classes.passwordInputL
            : size === 'm' ? classes.passwordInputM
            : classes.passwordInputS
          }
          classes = {{
            input: classes.centered
          }}
          autoComplete = 'current-password'
          id = 'password-text-field'
          type = {showPassword ? 'text' : 'password'}
          value = {passwordValue}
          onChange = {userInputFunction('passwordValue')}
          endAdornment = {
            <InputAdornment
              position = 'end'
            >
              <Typography
                onClick = {() => handleToggle('showPassword')}
                onMouseDown = {handlePreventDefault}
                className = {
                  size === 'l' ? classes.visibilityL
                  : size === 'm' ? classes.visibilityM
                  : classes.visibilityS
                }
                variant = 'subtitle2'
              >
                {showPassword ? words.hide : words.show}
              </Typography>
            </InputAdornment>
          }
        />
      </Grid>
    </Fragment>
  );
}

export default withStyles(styles)(UserInputs);