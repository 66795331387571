const inputCheck = (name, value) => {
  // Takes in name and value, makes sure that the inputs are correct before allowing them to be changed
  console.log(name, value);
  // if (value.length === 0) {
  if (name === 'phoneCheck') {
    if (value.length <= 6) {
      const test = /^[0-9]*$/.test(value);
      return test;
    }
  } else {
    return true;
  }
  // }

}

export default inputCheck;