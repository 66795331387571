import React, {
  Fragment,
} from 'react';

import {
  Button,
  Grid,
  Input,
  Typography,
  withStyles,
} from '@material-ui/core';

import ActionButton from '../Login/ActionButton/ActionButton';

const styles = theme => ({
  backButtonS: {
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '20px',
    fontSize: '14px',
    marginTop: '8px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  backButtonM: {
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '24px',
    fontSize: '20px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  backButtonL: {
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '21px',
    fontSize: '18px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  inputS: {
    // paddingLeft: '16px',
    borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
    // color: '#555555',
    // width: '100%',
    // maxWidth: '245px',
    // fontSize: '12px',
  },
  inputM: {
    borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
    // fontSize: '34px',
    // color: '#55555',
    // width: '100%',
    // maxWidth: '442px',
  },
  inputL: {
    borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
    // fontSize: '34px',
    // color: '#55555',
    // width: '100%',
    // maxWidth: '442px',
  },
  inputCenter: {
    textAlign: 'center',
  },
  inputGridS: {
    marginBottom: '87px',
    maxWidth: '285px',
    width: '100%',
  },
  inputGridM: {
    marginBottom: '112px',
    maxWidth: '442px',
    padding: '0px 32px',
    width: '100%',
  },
  inputGridL: {
    marginBottom: '88px',
    maxWidth: '442px',
    width: '100%',
  },
  inputLabelS: {
    marginBottom: '10px',
  },
  inputLabelM: {
    marginBottom: '10px',
  },
  inputLabelL: {
    marginBottom: '10px',
  },
  textS: {
    marginBottom: '43px',
    maxWidth: '295px',
  },
  textM: {
    marginBottom: '48px',
    maxWidth: '447px',
    padding: '0px 32px',
  },
  textL: {
    marginBottom: '48px',
  },
  titleS:{ 
    marginTop: '119px',
    marginBottom: '12px',
  },
  titleM:{ 
    marginBottom: '32px',
  },
  titleL:{ 
    marginBottom: '24px',
  },
});

const Forgot = (props) => {
  const {
    classes,
    emailValue,
    forgot,
    size,
    words,
    actionButtonFunction,
    backFunction,
    inputFunction,
  } = props;
  let disabled = true;
  if (forgot === 'Username') {
    if (emailValue.indexOf('@') > -1) {
      const startIndex = emailValue.indexOf('@');
      const testValue = emailValue.substring(startIndex);
      if (testValue.indexOf('.') > -1) {
        disabled = false;
      }
    }
  } else {
    if (emailValue.indexOf('@') > -1) {
      const startIndex = emailValue.indexOf('@');
      const testValue = emailValue.substring(startIndex);
      if (testValue.indexOf('.') > -1) {
        disabled = false;
      }
    } else if (emailValue.length >= 4) {
      disabled = false;
    }
  }
  return(
    <Fragment>
      <Grid
        item
        className = {
          size === 'l' ? classes.titleL
          : size === 'm' ? classes.titleM
          : classes.titleS
        }
      >
        <Typography
          align = 'center'
          variant = {
            size === 's' ? 'body1'
            : 'subtitle1'
          }
        >
          {forgot === 'Username' ? words.usernameTitle : words.passwordTitle}
        </Typography>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.textL
          : size === 'm' ? classes.textM
          : classes.textS
        }
      >
        <Typography
          align = 'center'
          variant = {
            size === 'l' ? 'body1'
            : size === 'm' ? 'h2'
            : 'h5'
          }
        >
          {forgot === 'Username' ? words.usernameText : words.passwordText}
        </Typography>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.inputLabelL
          : size === 'm' ? classes.inputLabelM
          : classes.inputLabelS
        }
      >
        <Typography
          align = 'center'
          variant = {
            size === 'm' ? 'h6'
            : 'h5'
          }
        >
          {forgot === 'Username' ? words.email : words.emailUser}
        </Typography>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.inputGridL
          : size === 'm' ? classes.inputGridM
          : classes.inputGridS
        }
      >
        <Input
          disableUnderline
          fullWidth
          type = 'email'
          value = {emailValue}
          classes = {{
            root: size === 'l' ? classes.inputL 
              : size === 'm' ? classes.inputM 
              : classes.inputS,
            input: classes.inputCenter,
          }}
          onChange = {inputFunction('emailValue')}
        />
      </Grid>
      <ActionButton
        disabled = {disabled}
        size = {size}
        type = {'forgot' + forgot}
        words = {forgot === 'Username' ? words.actionButtonUsername : words.actionButtonPassword}
        actionButtonFunction = {actionButtonFunction}
      />
      <Grid
        item
      >
        <Button
          disableFocusRipple
          disableRipple
          fullWidth
          onClick = {() => backFunction()}
          className = {
            size === 'l' ? classes.backButtonL
            : size === 'm' ? classes.backButtonM
            : classes.backButtonS
          }
        >
          {words.backButton}
        </Button>
      </Grid>
    </Fragment>
  )
}

export default withStyles(styles)(Forgot);