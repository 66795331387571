import React from 'react';
import { Grid, IconButton, Typography, withStyles } from '@material-ui/core';

// import CloseIcon from '@material-ui/icons/CloseRounded';
import exitButton from '../../../../../../static/svgs/ExitButton.svg';
import exitButtonDark from '../../../../../../static/svgs/ExitButtonDark.svg';

const styles = theme => ({
  fullWidth: {
    width: '100%',
  },
  headerS: {
    // backgroundColor: '#1e88e5',
    width: '100%',
    height: '153px',
  },
  headerM: {
    width: '100%',
    height: '190px'
  },
  headerL: {
    width: '100%',
    height: '190px',
  },
  iconS: {
    // boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    borderRadius: '50px',
    height: '22px',
    width: '22px',
  },
  iconM: {
    borderRadius: '50px',
    height: '31px',
    width: '31px',
  },
  iconL: {
    borderRadius: '50px',
    height: '40px',
    width: '40px',
  },
  lightText:{
    color: theme.palette.text.secondary,
  },
  menuButtonS: {
    padding: '0px',
    position: 'absolute',
    top: '8px',
    right: '16px',
  },
  menuButtonM: {
    padding: '0px',
    position: 'absolute',
    top: '24px',
    right: '24px',
  },
  menuButtonL: {
    padding: '0px',
    position: 'absolute',
    top: '24px',
    right: '32px',
  },
  textBottomS: {
    // paddingLeft: '32px',
    width: '100%',
  },
  textBottomM: {
    // paddingLeft: '40px',
    width: '100%',
  },
  textBottomL: {
    // paddingLeft: '40px',
    width: '100%',
  },
  textTopS: {
    // paddingLeft: '32px',
    width: '100%',
    marginTop: '72px',
    marginBottom: '4px',
  },
  textTopM: {
    // paddingLeft: '40px',
    width: '100%',
    marginTop: '91px',
    marginBottom: '12px',
  },
  textTopL: {
    // paddingLeft: '40px',
    width: '100%',
    marginTop: '91px',
    marginBottom: '12px',
  },
  titleText: {
    color: 'white'
  },
});

const SideBarHeader = (props) => {
  const {
    classes,
    darkFlag,
    size,
    toggleDrawer,
  } = props;

  return (
    <Grid
      container
      alignItems = 'flex-start'
      direction = 'column'
      className = {
        size === 'l' ? classes.headerL 
        : size === 'm' ? classes.headerM 
        : classes.headerS
      }
    >
      <Grid
        item
        className = {classes.fullWidth}
      >
        <IconButton
          aria-label = 'Close'
          color = 'inherit'
          className = {
            size === 'l' ? classes.menuButtonL 
            : size === 'm' ? classes.menuButtonM 
            : classes.menuButtonS
          }
          onClick = {() => {toggleDrawer()}}
        >
          <img
            src = {
              darkFlag ? exitButtonDark
              : exitButton
            }
            alt = 'Close'
            className = {
              size === 'l' ? classes.iconL 
              : size === 'm' ? classes.iconM 
              : classes.iconS
            }
          />
        </IconButton>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.textTopL 
          : size === 'm' ? classes.textTopM 
          : classes.textTopS
        }
      >
        <Typography
          align = 'center'
          variant = 'body2'
        >
          Need Help?
        </Typography>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.textBottomL 
          : size === 'm' ? classes.textBottomM 
          : classes.textBottomS
        }
      >
        <Typography
          align = 'center'
          variant = {
            size === 'l' ? 'h5'
            : 'subtitle2'
          }
          className = {classes.lightText}
        >
          Here's Our Info.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SideBarHeader);
