import React, { Fragment } from 'react';

import NavBar from './NavBar/NavBar';

// import Spacer from '../Spacer';

const TopBar = (props) => {
  const {
    data,
    darkFlag,
    error,
    size,
    // showMenu,
  } = props;

  let showMenu = 0;
  if (data.address || data.email || data.web || (data.address && data.google_place_id)) {
    showMenu = 1;
  }
  return(
    <Fragment>
      <NavBar 
        data = {data}
        darkFlag = {darkFlag}
        error = {error}
        size = {size}
        showMenu = {showMenu}
      />
      {/* {size !== 'l' && (
        <Spacer
          space={size === 'm' ? '43px' : '30px'}
        />
      )} */}
    </Fragment>
  );
}

export default TopBar;