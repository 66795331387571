import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';

import Main from './components/Main/Main';
import { unregister } from './serviceWorker'

import Responsive from './components/Helpers/Responsive/Responsive';

// import theme from './static/theme';


import themeSmall from './static/themeSmall';
import themeSmallDark from './static/themeSmallDark';
import themeMedium from './static/themeMedium';
import themeMediumDark from './static/themeMediumDark';
import themeLarge from './static/themeLarge';
import themeLargeDark from './static/themeLargeDark';

window.appVersion = 'Version 0.0.1.9';
console.log(window.appVersion);

class App extends Component {
  state = {
    dark: false,
  }

  darkTime = (dark) => {
    this.setState({
      dark: dark,
    });
  }

  render() {
    const {
      dark,
    } = this.state;
    unregister();
    if (dark) {
      return (
        <div className="App">
          <Responsive
            small = {
              <MuiThemeProvider
                theme = {themeSmallDark}
              >
                <Main
                  size = 's'
                  darkTime = {this.darkTime}
                />
              </MuiThemeProvider>
            }
            medium = {
              <MuiThemeProvider
                theme = {themeMediumDark}
              >
                <Main
                  size = 'm'
                  darkTime = {this.darkTime}
                />
              </MuiThemeProvider>
            }
            large = {
              <MuiThemeProvider
                theme = {themeLargeDark}
              >
                <Main
                  size = 'l'
                  darkTime = {this.darkTime}
                />
              </MuiThemeProvider>
            }
          />
        </div>
      );
    } else {
      return (
        <div className="App">
          <Responsive
            small = {
              <MuiThemeProvider
                theme = {themeSmall}
              >
                <Main
                  size = 's'
                  darkTime = {this.darkTime}
                />
              </MuiThemeProvider>
            }
            medium = {
              <MuiThemeProvider
                theme = {themeMedium}
              >
                <Main
                  size = 'm'
                  darkTime = {this.darkTime}
                />
              </MuiThemeProvider>
            }
            large = {
              <MuiThemeProvider
                theme = {themeLarge}
              >
                <Main
                  size = 'l'
                  darkTime = {this.darkTime}
                />
              </MuiThemeProvider>
            }
          />
        </div>
      );
    }
  }
}

export default App;