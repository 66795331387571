import React, { Fragment } from 'react';
import { Button, Grid, withStyles } from '@material-ui/core';

import poweredByYAPI from '../../../../static/svgs/PoweredbyYAPI.svg';

const styles = theme => ({
  bottomBarS: {
    maxWidth: '375px',
    width: '100%',
  },
  bottomBarM: {
    width: '100%',
    maxWidth: '768px',
  },
  bottomBarL: {
    width: '100%',
  },
  nextButtonS: {
    width: '311px',
    height: '48px',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: '15px',
    boxShadow: 'none',
  },
  nextButtonM: {
    width: '366px',
    height: '56px',
    borderRadius: '56px',
    fontWeight: 'bold',
    fontSize: '24px',
    marginTop: '15px',
    boxShadow: 'none',
  },
  nextButtonL: {
    width: '311px',
    height: '48px',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: '15px',
    boxShadow: 'none',
  },
  emailNextButtonL: {
    width: '228px',
    height: '48px',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: '15px',
    boxShadow: 'none',
  },
  backButtonS: {
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '20px',
    fontSize: '14px',
    marginTop: '8px',
  },
  backButtonM: {
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '24px',
    fontSize: '20px',
    marginTop: '16px',
  },
  backButtonL: {
    color: '#555555',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '20px',
    fontSize: '14px',
    marginTop: '16px',
  },
  powerGridS: {
    marginTop: '56px',
    // marginBottom: '16px',
  },
  powerGridM: {
    marginTop: '56px'
    // marginBottom: '48px',
  },
  powerImageS: {
    height: '18px',
    width: '92px',
  },
  powerImageM: {
    height: '24px',
    width: '124px',
  },
});

const Buttons = (props) => {
  const {
    classes,
    abort,
    // nextButton,
    // backButton,
    contact,
    disabled,
    loginRetry,
    nexturl,
    severity,
    size,
    type,
    // web,
    contactFunction,
    goBackFunction,
  } = props;
  let nextButton;
  let backButton;
  if (parseInt(loginRetry) === 1) {
    backButton = 'Retry';
  }
  if (parseInt(abort) === 0 || parseInt(severity) === 0) {
    nextButton = 'Contact Us';
    backButton = 'Go Back';
  } else {
    nextButton = 'Contact Us';
  }
  const emailNext = 'Let Me Continue';
  if (size === 'l') {
    return(
      <Fragment>
        <Grid
          item
          className = {classes.bottomBarL}
        >
          <Grid
            container
            direction = {
              type === 'email' ? 'row' 
              : 'column'
            }
            alignItems = 'center'
            justify = {
              type === 'email' ? 'space-between' 
              : 'center'
            }
          >
            { (backButton) && (
              <Grid
              item
              className = {classes.margined}
              >
                <Button
                  className = {classes.nextButtonL}
                  color = 'primary'
                  disabled = {disabled}
                  id = 'bottomBarNextButton'
                  variant = 'contained'
                  onClick = {() => goBackFunction()}
                >
                  {backButton}
                </Button>
              </Grid>
            )}
            {contact && !loginRetry && (
              <Grid
                item
                className = {classes.margined}
              >
                <Button
                  className = {
                    type === 'email' ? classes.emailNextButtonL 
                    : backButton ? classes.backButtonL
                    : classes.nextButtonL
                  }
                  color = 'primary'
                  disabled = {disabled}
                  id = 'bottomBarNextButton'
                  variant = {backButton ? 'text' : 'contained'}
                  onClick = {() => contactFunction()}
                >
                  {type === 'email' ? emailNext : nextButton}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  } else {
    return(
      <Fragment>
        <Grid
          item
          className = {
            size === 'm' ? classes.bottomBarM 
            : classes.bottomBarS
          }
        >
          <Grid
            container
            alignItems = 'center'
            direction = 'column'
            justify = 'center'
          >
            { backButton && (
              <Grid
              item
              className = {classes.margined}
              >
                <Button
                  className = {
                    size === 'm' ? classes.nextButtonM 
                    : classes.nextButtonS
                  }
                  color = 'primary'
                  disabled = {disabled}
                  id = 'bottomBarNextButton'
                  variant = 'contained'
                  onClick = {() => goBackFunction(nexturl)}
                >
                  {backButton}
                </Button>
              </Grid>
            )}
            <Grid
              item
              className = {classes.margined}
            >
              <Button
                className = {
                  backButton ? (
                    size === 'm' ? classes.backButtonM 
                    : classes.backButtonS
                  ) : size === 'm' ? classes.nextButtonM
                  : classes.nextButtonS
                }
                color = 'primary'
                disabled = {disabled}
                id = 'bottomBarNextButton'
                variant = {backButton ? 'text' : 'contained'}
                onClick = {() => contactFunction()}
              >
                {type === 'email' ? emailNext : nextButton}
              </Button>
            </Grid>
            <Grid
              item
              className = {
                size === 'm' ? classes.powerGridM 
                : classes.powerGridS
              }
            >
              <img
                alt = 'Powered By YAPI'
                className = {
                  size === 'm' ? classes.powerImageM 
                  : classes.powerImageS
                }
                src = {poweredByYAPI}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Buttons);