import React, { Fragment } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

import Buttons from './Buttons/Buttons';

import LargeView from '../../Helpers/LargeView/LargeView';

import ErrorFour from '../../../static/error/PageNotFound.png';
import SiteMaintenance from '../../../static/error/UnderMaintenance.png';
import ErrorFive from '../../../static/error/SomethingWentWrong.png';
import ApptAlreadyScheduled from '../../../static/error/LinkHasExpired.png';
import LinkDisabled from '../../../static/error/LinkDisabled.png';
import ReturningPatient from '../../../static/error/ReturningPatient.png';
// import LinkDoesNotExist from '../../../static/error/UnableToProcessRequest.png';
import LinkDoesNotExist from '../../../static/pngs/NoAttempts.png';
import LinkDoesNotExistS from '../../../static/pngs/NoAttemptsMobile.png';

const styles = theme => ({
  errorGrid: {
    margin: '8px',
    whiteSpace: 'pre-line',
  },
  errorGridL: {
    whiteSpace: 'pre-line',
  },
  mainGrid: {
    height: 'calc(100vh - 172px)',
  },
  mainGridS: {
    height: '100vh',
  },
  errorContent: {
    width: '100%',
  },
  imageS: {
    height: '250px',
    width: '250px',
  },
  imageSE: {
    height: '175px',
    width: '175px',
  },
  imageM: {
    height: '400px',
    width: '400px'
  },
  imageME: {
    height: '299px',
    width: '299px',
  },
  imageL: {
    height: '580px',
    width: '580px',
  },
  titleGrid: {

  },
  subtitleGrid: {
    marginTop: '8px',
  },
  textGridS: {
    maxWidth: '300px',
    marginTop: '8px',
  },
  textGridM: {
    maxWidth: '400px',
    marginTop: '16px',
  },
  textGridL: {
    maxWidth: '445px',
    marginTop: '16px',
  },
  emailTextGridL: {
    maxWidth: '500px',
    marginTop: '16px',
  },
  bottomBarS: {
    marginTop: '42px',
    marginBottom: '50px',
  },
  bottomBarM: {
    marginTop: '153px',
    marginBottom: '100px',
  },
  bottomBarL: {
    marginTop: '86px',
  },
});

const images = {
  one: {
    image: ErrorFour,
    title: "Error Four",
    // next: 'Go Back',
    // back: 'Contact Us',
  },
  two: {
    image: SiteMaintenance,
    title: "Site Maintenance",
    // next: 'Go Back',
    // back: 'Contact Us',
  },
  three: {
    image: ErrorFive,
    title: "Error Five",
    // next: 'Go Back',
    // back: 'Contact Us',
  },
  four: {
    image: LinkDisabled,
    title: "Link Disabled",
    // next: 'Go to Home Page',
    // back: 'Contact Us',
  },
  five: {
    image: ApptAlreadyScheduled,
    title: "Appointment Already Scheduled",
    // next: 'Go to Home Page',
    // back: 'Contact Us',
  },
  six: {
    image: ReturningPatient,
    title: "Returning Patient",
    // next: 'Contact Us',
    // back: 'Go to Home Page',
  },
  banana: {
    image: LinkDoesNotExist,
    imageS: LinkDoesNotExistS,
    title: "Link Does Not Exist",
    // next: 'Go to Home Page',
    // back: 'Contact Us',
  },
};

const Error = (props) => {
  const {
    classes,
    abort,
    logger,
    loginRetry,
    message,
    nexturl,
    practice,
    severity,
    size,
    subtitle,
    title,
    type,
    // contact,
    contactFunction,
    goBackFunction,
  } = props;
  logger && console.log('Error Type:', type);
  let contact = false;
  if (practice) {
    if (practice.address || practice.email || practice.web || (practice.address && practice.google_place_id)) {
      contact = true;
    }
  }
  let key = 'banana'
  if (parseInt(type) === 1) {
    //404
    key = 'one';
  } else if (parseInt(type) === 2) {
    // under construction
    key = 'two';
  } else if (parseInt(type) === 3) {
    key = 'three';
  } else if (parseInt(type) === 4) {
    key = 'four';
  } else if (parseInt(type) === 5) {
    key = 'five';
  } else if (parseInt(type) === 6) {
    key = 'six';
  }
  if (size ==='l') {
    return(
      <LargeView
        leftColumn = {(
          <Fragment>
            <Grid
              item
              className = {
                type === 'email' ? classes.emailTitleGrid 
                : classes.titleGrid
              }
            >
              <Typography
                variant = 'h1'
              >
                {title ? title : null}
              </Typography>
            </Grid>
            <Grid
              item
              className = {
                type === 'email' ? classes.emailSubtitleGrid 
                : classes.subtitleGrid
              }
            >
              <Typography
                variant = 'body2'
              >
                {subtitle ? subtitle : null}
              </Typography>
            </Grid>
            <Grid
              item
              className = {
                type === 'email' ? classes.emailTextGridL 
                : classes.textGridL
              }
            >
              <Typography
                variant = 'h5'
              >
                {message ? message : null}
              </Typography>
            </Grid>
            <Grid
              item
              className = {
                type === 'email' ? classes.emailBottomBarL 
                : classes.bottomBarL
              }
            >
              <Buttons
                abort = {abort}
                contact = {contact}
                loginRetry = {loginRetry}
                nexturl = {nexturl}
                size = {size}
                type = {type}
                goBackFunction = {goBackFunction}
                contactFunction = {contactFunction}
              />
            </Grid>
          </Fragment>
        )}
        rightColumn = {(
          <Grid
            item
            className = {
              type === 'email' ? classes.emailImageGridL 
              : classes.imageGridL
            }
          >
            <img
              alt = {images[key].title}
              src = {images[key].image}
              className = {
                type === 'email' ? classes.emailImageL 
                : classes.imageL
              }
            />
          </Grid>
        )}
        customSize = {
          type === 'email' ? 'email' 
          : 'error'
        }
      />
    );
   } else { 
    return(
      <Grid
        container
        alignItems = 'center'
        direction = 'column'
        justify = 'center'
        className = {
          size === 'm' ? classes.mainGridM 
          : classes.mainGridS
        }
      >
        <Grid
          item
          className = {
            size === 'm' ? classes.imageGridM 
            : classes.imageGridS
          }
        >
          <img
            src = {images[key].image}
            alt = {images[key].title}
            className = {
              size === 'm' ? classes.imageM 
              : classes.imageS
            }
          />
        </Grid>
        <Grid
          item
          className = {classes.titleGrid}
        >
          <Typography
            align = 'center'
            variant = 'h1'
          >
            {title ? title : null}
          </Typography>
        </Grid>
        <Grid
          item
          className = {classes.subtitleGrid}
        >
          <Typography
            align = 'center'
            variant = 'body2'
          >
            {subtitle ? subtitle : null}
          </Typography>
        </Grid>
        <Grid
          item
          className = {
            size === 'm' ? classes.textGridM 
            : classes.textGridS
          }
        >
          <Typography
            align = 'center'
            variant = 'h5'
          >
            {message ? message : null}
          </Typography>
        </Grid>
        <Grid
          item
          className = {
            size === 'm' ? classes.bottomBarM 
            : classes.bottomBarS
          }
        >
          <Buttons
            abort = {abort}
            contact = {contact}
            loginRetry = {loginRetry}
            nexturl = {nexturl}
            severity = {severity}
            size = {size}
            goBackFunction = {goBackFunction}
            contactFunction = {contactFunction}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Error);