import React, {
  Fragment,
} from 'react';

import {
  Checkbox,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import Tooltip from '../../../Helpers/Tooltip/Tooltip';

const styles = theme => ({
  blueText: {
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkBox: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0) !important',
    }
  },
  checkGridS: {
    marginBottom: '3px',
  },
  checkGridM: {
    marginBottom: '24px',
  },
  checkGridL: {
    marginBottom: '16px',
  },
  forgotGridS: {
    marginBottom: '9px',
  },
  forgotGridM: {
    marginBottom: '24px',
  },
  forgotGridL: {
    marginBottom: '16px',
  },
});

const RememberForgot = (props) => {
  const {
    classes,
    rememberUserToggle,
    size,
    userLogged,
    words,
    forgotFunction,
    handleToggle,
  } = props;

  return (
    <Fragment>
      <Grid
        item
        className = {
          size === 'l' ? classes.checkGridL
          : size === 'm' ? classes.checkGridM
          : classes.checkGridS
        }
      >
        <Grid
          container
          alignItems = 'center'
          justify = 'center'
          className = {classes.wide}
        >
          <Grid
            item
          >
            <Checkbox
              disableRipple
              className = {classes.checkBox}
              checked = {rememberUserToggle}
              value = 'rememberUserToggle'
              onChange = {() => handleToggle('rememberUserToggle')}
            />
          </Grid>
          <Grid
            item
          >
            <Typography
              variant = {
                size === 'l' ? 'h6'
                : size === 'm' ? 'subtitle2'
                : 'h6'
              }
            >
              {words.remember}
            </Typography>
          </Grid>
          <Grid
            item
          >
          <Tooltip
            size = {size}
            text = {words.rememberTooltip}
          />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.forgotGridL
          : size === 'm' ? classes.forgotGridM
          : classes.forgotGridS
        }
      >
        <Typography
          variant = 'h4'
          // className = {classes.blueText}
        >
          { userLogged ? (
            <span
              className = {classes.blueText}
              onClick = {forgotFunction('password')}
            >
              {words.onlyPassword}
            </span>
          ) : (
            <Fragment>
              <span
                className = {classes.blueText}
                onClick = {forgotFunction('username')}
              >
                {words.forgotUsername}
              </span>
              {words.or}
              <span
                className = {classes.blueText}
                onClick = {forgotFunction('password')}
              >
                {words.forgotPassword}
              </span>
          </Fragment>
          )}
          
        </Typography>
      </Grid>
    </Fragment>
  );
}

export default withStyles(styles)(RememberForgot);