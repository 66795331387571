import React from 'react';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

// import createImage from '../../../static/pngs/Social.png';
import lockImage from '../../../static/pngs/Locked.png';
// import passwordImage from '../../../static/pngs/CreatePassword.png';
import registerImage from '../../../static/pngs/Register.png';
import unlockImage from '../../../static/pngs/UnlockedLarge.png'
// import verifyImage from '../../../static/pngs/VerifyIdentity.png';

const styles = theme => ({
  imageGridContainer: {
    width: '100%',
    height: '100vh',
  },
  imageGrid: {
    width: '100%',
    maxWidth: '424px',
    maxHeight: '424px',
    padding: '0px 24px',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  insideGrid: {
    height: '100vh',
    width: '100%',
  },
  insideWrapper: {
    height: '100vh',
    maxWidth: '502px',
    width: '100%',
  },
  insideWrapperM: {
    height: '100vh',
    maxWidth: '546px',
    padding: '0px 0px',
    width: '100%',
  },
  leftGrid: {
    minWidth: '422px',
    width: '50%',
    height: 'calc(100vh - 64px)',
    // paddingTop: '64px',
  },
  main: {
    height: '100vh',
    width: '100%',
  },
  mainGrid: {
    height: '100vh',
    width: '100%',
    flexWrap: 'nowrap',
  },
  rightGrid: {
    flexGrow: 1,
    height: '100vh',
    // width: '50%',
  }
});

const TemplateView = props => {
  const {
    classes,
    dark,
    inside,
    size,
    step,
    verified,
  } = props;
  let determinedImage;
  let determinedBackground = {
    backgroundColor: dark ? '#121212' : '#FFFFFF',
  };
  if (parseInt(step) === 1) {
    if (verified) {
      determinedImage = unlockImage;
      determinedBackground = {
        backgroundColor: '#B9D468',
      }
    } else {
      determinedImage = lockImage;
      determinedBackground = {
        backgroundColor: '#E27964',
      }
    }
  } else {
    determinedImage = registerImage;
    determinedBackground = {
      backgroundColor: '#F4D345',
    };
  }
  return(
    <Grid
      item
      className = {classes.main}
    >
      <Grid
        container
        direction = 'row'
        className = {classes.mainGrid}
      >
        <Grid
          item
          className = {classes.leftGrid}
        >
          <Grid
            container
            alignItems = 'center'
            justify = 'center'
            className = {classes.insideGrid}
          >
            <Grid
              item
              className = {
                size === 'm' ? classes.insideWrapperM 
                : classes.insideWrapper
              }
            >
              {inside}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className = {classes.rightGrid}
          style = {determinedBackground}
        >
          <Grid
            container
            alignItems = 'center'
            justify = 'center'
            className = {classes.imageGridContainer}
          >
            <Grid
              item
              className = {classes.imageGrid}
            >
              <img
                alt = 'Hi'
                src = {determinedImage}
                className = {classes.image}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(TemplateView);