import React, {
  Fragment,
} from 'react';

import {
  Button,
  Fade,
  Input,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import Tooltip from '../../Helpers/Tooltip/Tooltip';

import unlocked from '../../../static/pngs/Unlocked.png';

const styles = theme => ({
  actionButtonS: {
    // width: '311px',
    height: '48px',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: '15px',
    boxShadow: 'none',
  },
  actionButtonM: {
    // width: '311px',
    height: '56px',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '24px',
    marginTop: '15px',
    boxShadow: 'none',
  },
  actionButtonL: {
    // width: '311px',
    height: '48px',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: '15px',
    boxShadow: 'none',
  },
  actionGridS:{
    // marginTop: '212px',
    paddingLeft: '32px',
    paddingRight: '32px',
    width: '100%',
  },
  actionGridM: {
    marginTop: '131px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
  },
  actionGridL: {
    marginTop: '131px',
    paddingLeft: '32px',
    paddingRight: '32px',
    width: '100%',
  },
  backButtonS: {
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '20px',
    fontSize: '14px',
    marginTop: '8px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  backButtonM: {
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '24px',
    fontSize: '20px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  backButtonL: {
    color: theme.palette.text.secondary,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '21px',
    fontSize: '18px',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  grayTextS: {
    color: theme.palette.text.secondary,
    marginTop: '12px',
    fontWeight: 'none',
  },
  grayTextM: {
    color: theme.palette.text.secondary,
    marginTop: '32px',
    fontWeight: 'none',
  },
  grayTextL: {
    color: theme.palette.text.secondary,
    marginTop: '27px',
    fontWeight: 'none',
  },
  hiderS: {
    backgroundColor: theme.palette.background.default,
    bottom: '0px',
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: '0px',
    zIndex: 10,
  },
  hiderM: {
    backgroundColor: theme.palette.background.default,
    bottom: '0px',
    position: 'absolute',
    right: '-15px',
    top: '0px',
    width: '18px',
    zIndex: 10,
  },
  hiderL: {
    backgroundColor: theme.palette.background.default,
    bottom: '0px',
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: '29px',
    zIndex: 10,
  },
  inputS: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.text.secondary} 45px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '63px 3px',
    backgroundRepeat: 'repeat-x',
    backgroundPositionX: '0px',
    // borderBottom: `5px solid rgba(${theme.palette.background.bottom}, 0.2)`,
    color: theme.palette.text.primary,
    height: '42px',
    fontSize: '32px',
    letterSpacing: '34px',
    marginLeft: '0px',
    // position: 'sticky',
    // overflowX: 'none',
    width: '360px',
  },
  inputM: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.text.secondary} 52px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '65px 5px',
    backgroundRepeat: 'repeat-x',
    backgroundPositionX: '0px',
    // borderBottom: `5px solid rgba(${theme.palette.background.bottom}, 0.2)`,
    color: theme.palette.text.primary,
    fontSize: '34px',
    height: '65px',
    letterSpacing: '48px',
    // overflowX: 'none',
    width: '392px',
  },
  inputL: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.text.secondary} 52px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '65px 5px',
    backgroundRepeat: 'repeat-x',
    backgroundPositionX: '0px',
    // borderBottom: `5px solid rgba(${theme.palette.background.bottom}, 0.2)`,
    color: theme.palette.text.primary,
    fontSize: '34px',
    letterSpacing: '48px',
    // overflowX: 'none',
    width: '100%',
  },
  inputConfirmS: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.border.confirm} 45px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '63px 3px',
    backgroundRepeat: 'repeat-x',
    backgroundPositionX: '0px',
    // borderBottom: `5px solid rgba(${theme.palette.background.bottom}, 0.2)`,
    color: theme.palette.text.green,
    height: '42px',
    fontSize: '32px',
    letterSpacing: '34px',
    marginLeft: '0px',
    // position: 'sticky',
    // overflowX: 'none',
    width: '360px',
  },
  inputConfirmM: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.border.confirm} 52px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '65px 5px',
    backgroundRepeat: 'repeat-x',
    backgroundPositionX: '0px',
    // borderBottom: `5px solid rgba(${theme.palette.background.bottom}, 0.2)`,
    color: theme.palette.text.green,
    fontSize: '34px',
    height: '65px',
    letterSpacing: '48px',
    // overflowX: 'none',
    width: '392px',
  },
  inputConfirmL: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.border.confirm} 52px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '65px 5px',
    backgroundRepeat: 'repeat-x',
    backgroundPositionX: '0px',
    // borderBottom: `5px solid rgba(${theme.palette.background.bottom}, 0.2)`,
    color: theme.palette.text.green,
    fontSize: '34px',
    letterSpacing: '48px',
    // overflowX: 'none',
    width: '100%',
  },
  inputErrorS: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.error.main2} 45px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '63px 3px',
    backgroundRepeat: 'repeat-x',
    backgroundPositionX: '0px',
    // borderBottom: `5px solid rgba(${theme.palette.background.bottom}, 0.2)`,
    color: theme.palette.error.main,
    height: '42px',
    fontSize: '32px',
    letterSpacing: '34px',
    marginLeft: '0px',
    // position: 'sticky',
    // overflowX: 'none',
    width: '360px',
    animation: `$2__x__1 50ms 0ms ease-in-out forwards, $2__x__2 100ms 50ms ease-in-out forwards, $2__x__3 100ms 150ms ease-in-out forwards, $2__x__4 100ms 250ms ease-in-out forwards, $2__x__5 50ms 350ms linear forwards`,
  },
  inputErrorM: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.error.main2} 52px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '65px 5px',
    backgroundRepeat: 'repeat-x',
    backgroundPositionX: '0px',
    // borderBottom: `5px solid rgba(${theme.palette.background.bottom}, 0.2)`,
    color: theme.palette.error.main,
    fontSize: '34px',
    height: '65px',
    letterSpacing: '48px',
    // overflowX: 'none',
    width: '392px',
    animation: `$2__x__1 50ms 0ms ease-in-out forwards, $2__x__2 100ms 50ms ease-in-out forwards, $2__x__3 100ms 150ms ease-in-out forwards, $2__x__4 100ms 250ms ease-in-out forwards, $2__x__5 50ms 350ms linear forwards`,
  },
  inputErrorL: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.error.main2} 52px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '65px 5px',
    backgroundRepeat: 'repeat-x',
    backgroundPositionX: '0px',
    // borderBottom: `5px solid rgba(${theme.palette.background.bottom}, 0.2)`,
    color: theme.palette.error.main,
    fontSize: '34px',
    letterSpacing: '48px',
    // overflowX: 'none',
    width: '100%',
    animation: `$2__x__1 50ms 0ms ease-in-out forwards, $2__x__2 100ms 50ms ease-in-out forwards, $2__x__3 100ms 150ms ease-in-out forwards, $2__x__4 100ms 250ms ease-in-out forwards, $2__x__5 50ms 350ms linear forwards`,
  },
  inputCenterS: {
    letterSpacing: '48px',
    overflow: 'hidden',
    paddingLeft: '14px',
    position: 'fixed',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    width: '400px',
  },
  inputCenterM: {
    letterSpacing: '48px',
    overflow: 'hidden',
    paddingLeft: '17px',
    position: 'fixed',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    width: '400px',
  },
  inputCenterL: {
    letterSpacing: '48px',
    paddingLeft: '17px',
    textAlign: 'left',
    width: '432px',
  },
  inputGridS: {
    marginTop: '12px',
    position: 'relative',
  },
  inputGridM: {
    marginTop: '61px',
    // overflowX: 'hidden',
    // paddingLeft: '29px',
    position: 'relative',
    // maxWidth: '435px',
    // width: '100%',
  },
  inputGridL: {
    marginTop: '57px',
    paddingLeft: '29px',
    position: 'relative',
    maxWidth: '435px',
    width: '100%',
  },
  mainS: {
    marginTop: '54px',
  },
  mainM: {

  },
  mainL: {

  },
  resendGridS: {
    marginTop: '16px',
  },
  resendGridM: {
    marginTop: '24px',
  },
  resendGridL: {
    marginTop: '16px',
  },
  resendButtonS: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: theme.palette.secondary.main,
    fontSize: '14px',
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  resendButtonM: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: theme.palette.secondary.main,
    fontSize: '20px',
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  resendButtonL: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: theme.palette.secondary.main,
    fontSize: '24px',
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
  verifiedContainer: {
    height: '100%',
    width: '100%',
  },
  verifiedGrid: {
    backgroundColor: theme.palette.background.verified,
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2000,
  },
  verifiedImage: {
    height: '100px',
    width: '75px',
  },
  verifiedImageGrid: {

  },
  verifiedText: {
    color: '#ffffff',
  },
  verifiedTextGrid: {
    marginTop: '26px',
  },
  wide: {
    marginTop: '4px',
  },
  '@keyframes 2__x__1': {
    from: {
      marginLeft: '0px',
    },
    to: {
      marginLeft: '4px',
    },
  },
  '@keyframes 2__x__2': {
      from: {
        marginLeft: '4px',
      },
      to: {
        marginLeft: '-4px',
      },
  },
  '@keyframes 2__x__3': {
      from: {
        marginLeft: '-4px',
      },
      to: {
        marginLeft: '4px',
      },
  },
  '@keyframes 2__x__4': {
      from: {
        marginLeft: '4px',
      },
      to: {
        marginLeft: '-4px',
      },
  },
  '@keyframes 2__x__5': {
      from: {
        marginLeft: '-4px',
      },
      to: {
        marginLeft: '0px',
      },
  },
});

const PhoneVerify = props => {
  const {
    classes,
    // loginType,
    phoneCheck,
    resendAttempt,
    size,
    user_phone,
    // username,
    verified,
    verifyError,
    words,
    // backFunction,
    buttonFunction,
    inputFunction,
    resendFunction,
  } = props;

  const resendDisabled = false;
  const disabled = phoneCheck.length !== 6;
  const displayResend = `(${resendAttempt + 1}/3)`;

  return(
    <Fragment>
      <Grid
        item
        className = {
          size === 'l' ? classes.mainL
          : size === 'm' ? classes.mainM
          : classes.mainS
        }
      >
        <Typography
          align = 'center'
          variant = 'body2'
        >
          {words.title}
        </Typography>
        <Grid
          container
          alignItems = 'flex-end'
          justify = 'center'
          className = {classes.wide}
        >
          <Grid
            item
          >
            <Typography
              align = 'center'
              variant = 'body1'
            >
              {words.subtitle}
            </Typography>
          </Grid>
          <Grid
            item
          >
            <Tooltip
              size = {size}
              text = {words.tooltip}
            />
          </Grid>
        </Grid>
        <Typography
          align = 'center'
          className = {
            size === 'l' ? classes.grayTextL
            : size === 'm' ? classes.grayTextM
            : classes.grayTextS}
          variant = {
            size === 'l' ? 'subtitle1'
            : 'h3'
          }
        >
          {`(***) ****-${user_phone}`}
        </Typography>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.inputGridL
          : size === 'm' ? classes.inputGridM
          : classes.inputGridS
        }
      >
        <Input
          disableUnderline
          autoComplete = 'one-time-code'
          disabled = {verified ? true : false}
          id = 'verify'
          type = 'tel'
          value = {phoneCheck}
          classes = { verifyError ? {
            root: size === 'l' ? classes.inputErrorL 
              : size === 'm' ? classes.inputErrorM
              : classes.inputErrorS,
            input: size === 'l' ? classes.inputCenterL
            : size === 'm' ? classes.inputCenterM
            : classes.inputCenterS,
            disabled: size === 'l' ? classes.inputConfirmL 
            : size === 'm' ? classes.inputConfirmM
            : classes.inputConfirmS,
          } : {
            root: size === 'l' ? classes.inputL 
              : size === 'm' ? classes.inputM 
              : classes.inputS,
            input: size === 'l' ? classes.inputCenterL
            : size === 'm' ? classes.inputCenterM
            : classes.inputCenterS,
            disabled: size === 'l' ? classes.inputConfirmL 
            : size === 'm' ? classes.inputConfirmM
            : classes.inputConfirmS,
          }}
          inputProps = {{
            maxLength: '6',
          }}
          onChange = {inputFunction('phoneCheck')}
        />
        <div
          className = {
            size === 'l' ? classes.hiderL
            : size === 'm' ? classes.hiderM
            : classes.hiderS
          }
        >

        </div>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.resendGridL
          : size === 'm' ? classes.resendGridM
          : classes.resendGridS
        }
      >
        <Button
          disableFocusRipple
          disableRipple
          fullWidth
          className = {
            size === 'l' ? classes.resendButtonL
            : size === 'm' ? classes.resendButtonM
            : classes.resendButtonS
          }
          color = 'secondary'
          disabled = {resendDisabled}
          variant = 'text'
          onClick = {() => resendFunction()}
        >
          {words.resend}{displayResend}
        </Button>
      </Grid>
      <Grid
        item
        className = {
          size === 'l' ? classes.actionGridL
          : size === 'm' ? classes.actionGridM
          : classes.actionGridS
        }
      >
        <Button
          fullWidth
          className = {
            size === 'l' ? classes.actionButtonL
            : size === 'm' ? classes.actionButtonM
            : classes.actionButtonS
          }
          color = 'primary'
          disabled = {disabled}
          variant = 'contained'
          onClick = {() => buttonFunction('verify')}
        >
          {words.actionButton}
        </Button>
      </Grid>
      { size === 's' && verified && (
        <Fade
          in = {verified}
          timeout = {200}
        >
          <Grid
            item
            className = {classes.verifiedGrid}
          >
            <Grid
              container
              alignItems = 'center'
              direction = 'column'
              justify = 'center'
              className = {classes.verifiedContainer}
            >
              <Grid
                item
                className = {classes.verifiedImageGrid}
              >
                <img
                  alt = {words.verified}
                  src = {unlocked}
                  className = {classes.verifiedImage}
                />
              </Grid>
              <Grid
                item
                className = {classes.verifiedTextGrid}
              >
                <Typography
                  variant = 'h1'
                  className = {classes.verifiedText}
                >
                  {words.verified}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      )}
    </Fragment>
  );
}

export default withStyles(styles)(PhoneVerify);