import React from 'react';
import { 
  Button,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions, 
  Typography, 
  withStyles, 
} from '@material-ui/core';

const styles = theme => ({
  actionsS: {
    justifyContent: 'center',
    marginBottom: '32px',
    flexDirection: 'column',
  },
  actionsM: {
    justifyContent: 'center',
    marginBottom: '36px',
    flexDirection: 'column',
  },
  actionsL: {
    justifyContent: 'center',
    marginBottom: '27px',
    flexDirection: 'column',
  },
  dialogS: {
    maxWidth: '292px',
    borderRadius: '20px',
    margin: 'auto',
  },
  dialogM: {
    maxWidth: '462px',
    borderRadius: '20px',
    margin: 'auto',
  },
  dialogL: {
    maxWidth: '462px',
    borderRadius: '20px',
    margin: 'auto',
  },
  dialogRootS: {
    padding: '0px 30px 16px',
  },
  dialogRootM: {
    padding: '0px 48px 16px',
  },
  dialogRootL: {
    padding: '0px 48px 16px',
  },
  modalText: {
    whiteSpace: 'pre-line',
  },
  rootS: {
    margin: '5px',
    minWidth: '292px',
    maxWidth: '292px',
    maxHeight: '450px',
    overflowY: 'auto',
  },
  rootM: {
    margin: '5px',
    minWidth: '462px',
    maxWidth: '462px',
    maxHeight: '553px',
    overflowY: 'auto',
  },
  rootL: {
    margin: '5px',
    minWidth: '373px',
    maxWidth: '373px',
    maxHeight: '460px',
    overflowY: 'auto',
  },
  titleRootS: {
    padding: '0px 30px 16px',
  },
  titleRootM: {
    padding: '0px 82px 16px',
  },
  titleRootL: {
    padding: '0px 54px 16px',
  },
  titleTextS: {
    paddingTop: '24px',
  },
  titleTextM: {
    paddingTop: '36px',
  },
  titleTextL: {
    paddingTop: '25px',
  },
  topButtonS: {
    width: '232px',
    height: '36px',
    fontSize: '14px',
    borderRadius: '50px',
    boxShadow: 'none',
    fontWeight: 'bold',
  },
  topButtonM: {
    width: '232px',
    height: '48px',
    fontSize: '20px',
    borderRadius: '50px',
    boxShadow: 'none',
    fontWeight: 'bold',
  },
  topButtonL: {
    width: '204px',
    height: '36px',
    fontSize: '16px',
    borderRadius: '50px',
    boxShadow: 'none',
    fontWeight: 'bold',
  },
});

const Modal = (props) => {
  const {
    buttonText,
    classes,
    modalOpen,
    size,
    title,
    text,
    handleModalClose,
  } = props;
  // logger && console.log('Modal props:', props);
  return(
    <Dialog
      aria-labelledby = 'alert-dialog-title'
      aria-describedby = 'alert-dialog-description'
      open = {modalOpen}
      onClose = {handleModalClose}
      className = {
        size === 'l' ? classes.dialogL 
        : size === 'm' ? classes.dialogM 
        : classes.dialogS
      }
      PaperProps={{
        classes: {
          root: size === 'l' ? classes.rootL 
          : size === 'm' ? classes.rootM 
          : classes.rootS,
        }
      }}
    >
      <DialogTitle
        disableTypography
        className = {
          size === 'l' ? classes.titleRootL 
          : size === 'm' ? classes.titleRootM 
          : classes.titleRootS
        }
      >
        <Typography
          align = 'left'
          variant = 'subtitle1'
          className = {
            size === 'l' ? classes.titleTextL 
            : size === 'm' ? classes.titleTextM 
            : classes.titleTextS
          }
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        className = {
          size === 'l' ? classes.dialogRootL 
          : size === 'm' ? classes.dialogRootM
          : classes.dialogRootS
        }
      >
        <DialogContentText
          id = 'alert-dialog-description'
          variant = {
            size === 'm' ? 'subtitle2'
            : 'h6'
          }
          color = 'textPrimary'
          className = {classes.modalText}
          align = 'justify'
        >
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className = {
          size === 'l' ? classes.actionsL 
          : size === 'm' ? classes.actionsM 
          : classes.actionsS
        }
      >
        <Button
          color = 'primary'
          variant = 'contained'
          className = {
            size === 'l' ? classes.topButtonL 
            : size === 'm' ? classes.topButtonM 
            : classes.topButtonS
          }
          onClick = {() => handleModalClose()}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(Modal);