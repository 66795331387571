import React from 'react';

import {
  Button,
  Grid,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  actionButtonS: {
    // width: '311px',
    height: '48px',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: '15px',
    boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.25)`,
  },
  actionButtonM: {
    // width: 311px',
    height: '56px',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '24px',
    marginTop: '16px',
    boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.25)`,
  },
  actionButtonL: {
    width: '311px',
    height: '48px',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '18px',
    marginTop: '16px',
    boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.25)`,
  },
  actionGridS:{
    paddingLeft: '32px',
    paddingRight: '32px',
    width: '100%',
  },
  actionGridM:{
    maxWidth: '396px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
  },
  actionGridL:{
    paddingLeft: '62px',
    paddingRight: '62px',
    width: '100%',
  },
});

const ActionButton = (props) => {
  const {
    classes,
    disabled,
    size,
    type,
    words,
    actionButtonFunction,
  } = props;
  return(
    <Grid
      item
      className = {
        size === 'l' ? classes.actionGridL
        : size === 'm' ? classes.actionGridM
        : classes.actionGridS
      }
    >
      <Button
        fullWidth
        className = {
          size === 'l' ? classes.actionButtonL
          : size === 'm' ? classes.actionButtonM
          : classes.actionButtonS
        }
        color = 'primary'
        disabled = {disabled}
        variant = 'contained'
        onClick = {() => actionButtonFunction(type)}
      >
        {words}
      </Button>
    </Grid>
  );
}

export default withStyles(styles)(ActionButton);