const kotoba = {
  'eng-us': {
    greeting: 'HELLO WORLD',
    error: {
      accept: 'OK',
      attemptText: "Looks like you've run out of attempts.",
      attemptTitle: 'Sorry about that,',
    },
    forgot: {
      actionButtonPassword: 'Reset Password',
      actionButtonUsername: 'Request Username',
      backButton: 'Go Back to Login Page',
      email: 'Enter Email',
      emailUser: 'Enter Username or Email',
      passwordText: 'Please enter your registered email address below to receive instructions on how to reset your password.',
      passwordTitle: 'Forgot Your Password?',
      usernameText: 'Please enter your registered email address below to receive further instructions.',
      usernameTitle: 'Forgot Your Username?',
    },
    login: {
      actionButton: 'Sign In',
      anotherAccount: 'Use Another Account',
      notYou: 'Not you?  ',
      or: 'or Sign In with',
      togglePassword: 'toggle password visibility',
      loggedIn: {
        actionButton: 'Continue with ',
        anotherAccount: 'Use Another Account',
        notYou: 'Not you?  ',
        signedIn: 'Signed in via ',
        welcome: 'Welcome to',
      },
      loginHeader: {
        welcome: 'Welcome to',
      },
      rememberForgot: {
        forgotPassword: 'Password?',
        forgotUsername: 'Forgot Username ',
        onlyPassword: 'Forgot Password?',
        or: 'or ',
        remember: 'Remember Username',
        rememberTooltip: "Check this box only if you're using a trusted device, like your personal computer or mobile device.",
      },
      userInputs: {
        hide: 'Hide',
        passwordInput: 'Password',
        passwordTooltip: 'You created a password with these requirements:\n\nAt least one uppercase letter\nAt least one number\nAt least six characters long',
        show: 'Show',
        userInput: 'Username',
      },
    },
    modal: {
      password: 'We sent an email with instructions on how to reset your password',
      username: 'Email was sent',
    },
    phoneVerify: {
      actionButton: 'Confirm Code',
      backButton: 'Go Back to User Verification',
      resend: 'Resend Code ',
      subActionButton: 'Go Back to Member Verification',
      subtitle: 'Please enter the code sent to: ',
      title: 'Enter Your Code',
      tooltip: "Our system needs to securely verify that the device belongs to the enrollee.",
      verified: 'Verified',
    },
  },
  'spa': {

  },
  'fra': {
  
  },
};

export default kotoba;