import { createMuiTheme } from '@material-ui/core/styles';

// const color = '#424242';
const color = 'rgb(255, 255, 255)';
const fonts = ['Roboto Condensed', 'Source Sans Pro', 'sans-serif'].join(',');

const themeMedium = createMuiTheme({
  typography: {
    useNextVariants: true,
    color: color,
    fontFamily: fonts,
    body1: {
      color: color,
      fontFamily: fonts,
      fontWeight: 'bold',
      fontSize: '24px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    body2: {
      color: color,
      fontFamily: fonts,
      fontSize: '34px',
      fontWeight: 'bold',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    button: {
      fontFamily: fonts,
      lineHeight: 1,
      textTransform: 'none',
      letterSpacing: '0px',
    },
    h1: {
      color: color,
      fontFamily: fonts,
      fontWeight: 'bold',
      fontSize: '42px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h2: {
      color: color,
      fontFamily: fonts,
      fontWeight: 'normal',
      fontSize: '24px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h3: {
      color: color,
      fontFamily: fonts,
      fontWeight: 'bold',
      fontSize: '22px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h4: {
      color: color,
      fontFamily: fonts,
      fontWeight: 'bold',
      fontSize: '20px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h5: {
      color: color,
      fontFamily: fonts,
      fontWeight: 'normal',
      fontSize: '22px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h6: {
      color: color,
      fontFamily: fonts,
      fontWeight: 'normal',
      fontSize: '20px',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    subtitle1: {
      color: color,
      fontFamily: fonts,
      fontSize: '28px',
      fontWeight: 'bold',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    subtitle2: {
      color: color,
      fontFamily: fonts,
      fontSize: '16px',
      fontWeight: 'normal',
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    caption: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: color,
    }
  },
  palette: {
    primary: 
    {
      main: '#F7B500', // gold for switches
    },
    secondary:
    {
      main: '#80BDFF', // blue for text
    },
    error: {
      main: '#FF4D4D', // red for error
      main2: 'rgba(255, 138, 138, 0.25)', // lighter red for lighter errors
    },
    action: {
      disabledBackground: '#3A3A3A',
      disabled: '#B8B8B8',
    },
    info: {
      main: '#3A3A3A',
    },
    text: {
      primary: '#ffffff', // white
      secondary: '#B8B8B8', // less white
      button: '#000000', // black for button text
      green: '#39DB67', // green for confirm
      darkOrange: '#F7931E', // dark orange for sidenav title
    },
    background: {
      bottom: '196, 196, 196',
      paper: '#1C1C1C', // dark
      default: '#121212', // blackish
      notification: '247, 181, 0',
      shading: '0, 0, 0,',
      tooltip: '#1C1C1C',
      verified: 'rgba(18, 18, 18, 0.9)',
      zeroes: '255, 255, 255'
    },
    border: {
      confirm: 'rgba(71, 205, 10, 0.25)',
      tooltip: '#3A3A3A',
    },
    type: 'dark',
    grey: {
      300: '#ffffff'
    }
  },
  overrides: {
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: color,
        margin: '0px 15px',
      },
      switchHeader: {
        fontWeight: 'bold !important',
      },
      transitionContainer: {
        fontWeight: 'bold !important',
      }
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
          WebkitTextFillColor:'white'
        },
      }
    },
  },
})
export default themeMedium;