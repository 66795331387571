import React from 'react';

import {
  Button,
  Grid,
  withStyles,
} from '@material-ui/core';

import appleIcon from '../../../../static/svgs/social/Apple.svg';
import facebookIcon from '../../../../static/svgs/social/Facebook.svg';
import googleIcon from '../../../../static/svgs/social/Google.svg';

const styles = theme => ({
  appleIconS: {
    height: '20px',
    width: '20px',
  },
  appleIconM: {
    height: '24px',
    width: '24px',
  },
  appleIconL: {
    height: '24px',
    width: '24px',
  },
  buttonCellS: {
    margin: '0px 4px',
  },
  buttonCellM: {
    flexGrow: 1,
    maxWidth: '136px',
    // width: '100%',
    margin: '0px 7px',
  },
  buttonCellL: {
    margin: '0px 7px',
  },
  buttonAIconS: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: '1px solid #000000',
    boxShadow: 'none',
    width: '80px',
    height: '40px',
    // margin: 'auto',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.75)`,
    },
    '&:active': {
      boxShadow: 'none',
    }
  },
  buttonAIconM: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: '2px solid #000000',
    boxShadow: 'none',
    maxWidth: '135px',
    width: '100%',
    height: '68px',
    // margin: 'auto',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.75)`,
    },
    '&:active': {
      boxShadow: 'none',
    }
  },
  buttonAIconL: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: '2px solid #000000',
    boxShadow: 'none',
    width: '135px',
    height: '68px',
    // margin: 'auto',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.75)`,
    },
    '&:active': {
      boxShadow: 'none',
    }
  },
  buttonFIconS: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: '1px solid #4267B2',
    boxShadow: 'none',
    width: '80px',
    height: '40px',
    // margin: 'auto',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.75)`,
    },
    '&:active': {
      boxShadow: 'none',
    }
  },
  buttonFIconM: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: '2px solid #4267B2',
    boxShadow: 'none',
    maxWidth: '135px',
    width: '100%',
    height: '68px',
    // margin: 'auto',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.75)`,
    },
    '&:active': {
      boxShadow: 'none',
    }
  },
  buttonFIconL: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: '2px solid #4267B2',
    boxShadow: 'none',
    width: '135px',
    height: '68px',
    // margin: 'auto',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.75)`,
    },
    '&:active': {
      boxShadow: 'none',
    }
  },
  buttonGIconS: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: '1px solid #4285F4',
    boxShadow: 'none',
    width: '80px',
    height: '40px',
    // margin: 'auto',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.75)`,
    },
    '&:active': {
      boxShadow: 'none',
    }
  },
  buttonGIconM: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: '2px solid #4285F4',
    boxShadow: 'none',
    maxWidth: '135px',
    width: '100%',
    height: '68px',
    // margin: 'auto',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.75)`,
    },
    '&:active': {
      boxShadow: 'none',
    }
  },
  buttonGIconL: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: '2px solid #4285F4',
    boxShadow: 'none',
    width: '135px',
    height: '68px',
    // margin: 'auto',
    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.75)`,
    },
    '&:active': {
      boxShadow: 'none',
    }
  },
  iconS: {
    height: '18px',
    width: '18px',
  },
  iconM: {
    height: '30px',
    width: '30px',
  },
  iconL: {
    height: '30px',
    width: '30px',
  },
  socialButtonsS: {
    maxWidth: '264px',
    width: '100%',
  },
  socialButtonsM: {
    maxWidth: '450px',
    padding: '0px 16px',
    width: '100%',
  },
  socialButtonsL: {
    maxWidth: '448px',
    width: '100%',
  },
  wide: {
    width: '100%',
  }
});

const SocialButtons = (props) => {
  const {
    classes,
    darkFlag,
    login,
    size,
    buttonFunction
  } = props;

  const {
    facebook_enabled,
    google_enabled,
    apple_enabled,
  } = login;
  return(
    <Grid
      item
      className = {
        size === 'l' ? classes.socialButtonsL
        : size === 'm' ? classes.socialButtonsM
        : classes.socialButtonsS
      }
    >
      <Grid
        container
        direction = 'row'
        alignItems = 'center'
        justify = 'center'
        className = {classes.wide}
      >
        {google_enabled ? (
          <Grid
            item
            className = {
              size === 'l' ? classes.buttonCellL
              : size === 'm' ? classes.buttonCellM
              : classes.buttonCellS
            }
          >
            <Button
              disableRipple
              className = {
                size === 'l' ? classes.buttonGIconL
                : size === 'm' ? classes.buttonGIconM
                : classes.buttonGIconS
              }
              variant = 'contained'
              onClick = {() => buttonFunction('g')}
            >
              <img
                alt = 'Google'
                src = {googleIcon}
                className = {
                  size === 'l' ? classes.iconL
                  : size === 'm' ? classes.iconM
                  : classes.iconS
                }
              />
            </Button>
          </Grid>
        ) : null }
        { facebook_enabled ? (
          <Grid
            item
            className = {
              size === 'l' ? classes.buttonCellL
              : size === 'm' ? classes.buttonCellM
              : classes.buttonCellS
            }
          >
            <Button
              disableRipple
              className = {
                size === 'l' ? classes.buttonFIconL
                : size === 'm' ? classes.buttonFIconM
                : classes.buttonFIconS
              }
              variant = 'contained'
              onClick = {() => buttonFunction('f')}
            >
              <img
                alt = 'Facebook'
                src = {facebookIcon}
                className = {
                  size === 'l' ? classes.iconL
                  : size === 'm' ? classes.iconM
                  : classes.iconS
                }
              />
            </Button>
          </Grid>
        ) : null }
        { apple_enabled ? (
          <Grid
            item
            className = {
              size === 'l' ? classes.buttonCellL
              : size === 'm' ? classes.buttonCellM
              : classes.buttonCellS
            }
          >
            <Button
              disableRipple
              className = {
                size === 'l' ? classes.buttonAIconL
                : size === 'm' ? classes.buttonAIconM
                : classes.buttonAIconS
              }
              variant = 'contained'
              onClick = {() => buttonFunction('a')}
            >
              <img
                alt = 'Apple'
                src = {appleIcon}
                className = {
                  size === 'l' ? classes.iconL
                  : size === 'm' ? classes.iconM
                  : classes.iconS
                }
              />
            </Button>
          </Grid>
        ) : null }
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SocialButtons);