import React, {
  Fragment,
} from 'react';

import { 
  Button,
  Grid,
  Tooltip,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  gridRow: {
  },
  buttonS: {
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '16px',
    width: '131px',
    height: '40px',
    boxShadow: 'none',
  },
  buttonM: {
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '22px',
    width: '179px',
    height: '58px',
    boxShadow: 'none',
  },
  buttonL: {
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '18px',
    width: '179px',
    height: '48px',
    boxShadow: 'none',
  },
  buttonGridS: {
    marginBottom: '12px',
  },
  buttonGridM: {
    marginBottom: '8px',
  },
  buttonGridL: {
    padding: '0px 16px 16px 0px',
  },
  buttonGridMainS: {
    width: '100%',
    height: '100%',
    marginTop: '3px',
  },
  buttonGridMainM: {
    width: '100%',
    height: '100%',
    marginTop: '3px',
    textAlign: 'center',
  },
  buttonGridMainL: {
    width: '100%',
    height: '100%',
    marginTop: '3px',
    textAlign: 'center',
  },
  buttonLeftS: {
    width: '30%',
    textAlign: 'left',
    margin: 'auto',
    // marginLeft: '0px',
  },
  buttonLeftM: {
    marginLeft: '8px',
  },
  buttonLeftL: {
    marginLeft: '8px',
  },
  buttonRightS: {
    width: '70%',
    textAlign: 'left',
  },
  buttonRightM: {
    marginLeft: '24px',
    marginRight: '16px',
  },
  buttonRightL: {
    marginLeft: '24px',
    marginRight: '20px',
  },
  tooltip: {
    backgroundColor: theme.palette.background.tooltip,
    borderRadius: '10px',
    boxShadow: `0px 4px 4px rgba(${theme.palette.background.shading} 0.25)`,
    color: theme.palette.text.primary,
    position: 'relative',
    maxWidth: '423px',
  },
});

const SideBarRow = (props) => {
  const {
    classes,
    contact,
    link,
    icon,
    size,
    text,
    tooltipText,
  } = props;
  if (size === 'l') {
    return(
      <Fragment>
        <Grid
          item
          className = {classes.buttonGridL}
        >
          <Tooltip
            classes = {{
              tooltip: classes.tooltip
            }}
            placement = 'bottom'
            title = {tooltipText}
            disableTouchListener
            disableFocusListener
          >
            <a
              href = {link}
            >
            <Button
              aria-label = {text}
              color = 'primary'
              variant = 'contained'
              className = {classes.buttonL}
              // onClick = {() => {window.location.href = link}}
              // onTouchStart = {() => {window.location.href = link}}
            >
              <Grid
                container
                alignItems = 'center'
                className = {classes.buttonGridMainL}
              >
                <Grid
                  item
                  className = {classes.buttonLeftL}
                >
                  {icon}
                </Grid>
                <Grid
                  item
                  className = {classes.buttonRightL}
                >
                  {text}
                </Grid>
              </Grid>
            </Button>
            </a>
          </Tooltip>
        </Grid>
      </Fragment>
    );
  } else {
    return(
      <Grid
        container
        alignItems = 'center'
        direction = 'row'
        justify = 'center'
        wrap = 'nowrap'
        className = {classes.gridRow}
      >
        <Grid
          item
          className = {
            size === 'm' && contact ? classes.buttonGridM 
            : classes.buttonGridS
          }
        >
          <Button
            aria-label = {text}
            color = 'primary'
            variant = 'contained'
            className = {
              size === 'm' ? classes.buttonM 
              : classes.buttonS
            }
            onClick = {() => {window.open(link)}}
          >
            <Grid
              container
              alignItems = 'center'
              className = {
                size === 'm' ? classes.buttonGridMainM 
                : classes.buttonGridMainS
              }
            >
              <Grid
                item
                className = {
                  size === 'm' ? classes.buttonLeftM 
                  : classes.buttonLeftS
                }
              >
                {icon}
              </Grid>
              <Grid
                item
                className = {
                  size === 'm' ? classes.buttonRightM 
                  : classes.buttonRightS}
              >
                {text}
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    );
  }
};

export default withStyles(styles)(SideBarRow);