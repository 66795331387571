import React, {
  Fragment,
} from 'react';

import {
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import ActionButton from './ActionButton/ActionButton';
import LoggedIn from './LoggedIn/LoggedIn';
import LoginHeader from './LoginHeader/LoginHeader';
import RememberForgot from './RememberForgot/RememberForgot';
import SocialButtons from './SocialButtons/SocialButtons';
import UserInputs from './UserInputs/UserInputs';

const styles = theme => ({
  blueText: {
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  changeAccountS: {
    marginTop: '40px',
  },
  changeAccountM: {
    marginTop: '56px',
  },
  changeAccountL: {
    marginTop: '48px',
  },
  divider: {
    height: '1px',
    backgroundColor: theme.palette.action.disabledBackground,
    flexGrow: 1,
  },
  orGridS: {
    marginBottom: '12px',
    marginTop: '16px',
    width: '100%',
    maxWidth: '200px',
  },
  orGridM: {
    marginBottom: '32px',
    marginTop: '32px',
    maxWidth: '450px',
    padding: '0px 16px',
    width: '100%',
  },
  orGridL: {
    marginBottom: '24px',
    marginTop: '24px',
    width: '100%',
    maxWidth: '388px'
  },
  orTextGridS: {
    marginLeft: '16px',
    marginRight: '16px',
  },
  orTextGridM: {
    marginLeft: '16px',
    marginRight: '16px',
  },
  orTextGridL: {
    marginLeft: '16px',
    marginRight: '16px',
  },
});

const Login = props => {
  const {
    classes,
    darkFlag,
    latestLogin,
    login,
    // logo,
    passwordValue,
    practice,
    rememberUserToggle,
    showPassword,
    size,
    userValue,
    words,
    forgotFunction,
    actionButtonFunction,
    buttonFunction,
    handlePreventDefault,
    handleToggle,
    userInputFunction,
  } = props;

  let disabled, logo, practiceName;

  if (practice) {
    if (practice.name) {
      practiceName = practice.name;
    }
    if (practice.logo_url) {
      logo = practice.logo_url;
    }
  }

  if (latestLogin) {
    if (latestLogin.login_type) {
      if (parseInt(latestLogin.login_type) === 1) {
        return(
          <LoggedIn
            darkFlag = {darkFlag}
            logo = {logo}
            patientName = {latestLogin.username}
            practiceName = {practiceName}
            size = {size}
            type = 'Google'
            words = {words.loggedIn}
            actionButtonFunction = {actionButtonFunction}
            forgotFunction = {forgotFunction}
          />
        );
      }
      if (parseInt(latestLogin.login_type) === 2) {
        return(
          <LoggedIn
            darkFlag = {darkFlag}
            logo = {logo}
            patientName = {latestLogin.username}
            practiceName = {practiceName}
            size = {size}
            type = 'Facebook'
            words = {words.loggedIn}
            actionButtonFunction = {actionButtonFunction}
            forgotFunction = {forgotFunction}
          />
        );
      }
      if (parseInt(latestLogin.login_type) === 3) {
        return(
          <LoggedIn
            darkFlag = {darkFlag}
            logo = {logo}
            patientName = {latestLogin.username}
            practiceName = {practiceName}
            size = {size}
            type = 'Apple'
            words = {words.loggedIn}
            actionButtonFunction = {actionButtonFunction}
            forgotFunction = {forgotFunction}
          />
        );
      }
      if (parseInt(latestLogin.login_type) === 4) {
        if (passwordValue.length === 0) {
          disabled = true;
        } else {
          disabled = false;
        }
        return (
          <Fragment>
            <LoginHeader
              darkFlag = {darkFlag}
              logo = {logo}
              patientName = {latestLogin.username}
              practiceName = {practiceName}
              size = {size}
              words = {words.loginHeader}
            />
            <UserInputs
              darkFlag = {darkFlag}
              passwordValue = {passwordValue}
              size = {size}
              showPassword = {showPassword}
              words = {words.userInputs}
              username = {latestLogin.username}
              userValue = {userValue}
              handlePreventDefault = {handlePreventDefault}
              handleToggle = {handleToggle}
              userInputFunction = {userInputFunction}
            />
            <RememberForgot
              userLogged
              darkFlag = {darkFlag}
              rememberUserToggle = {rememberUserToggle}
              size = {size}
              words = {words.rememberForgot}
              forgotFunction = {forgotFunction}
              handleToggle = {handleToggle}
            />
            <ActionButton
              darkFlag = {darkFlag}
              disabled = {disabled}
              size = {size}
              type = 'signInKnown'
              words = {words.actionButton}
              actionButtonFunction = {actionButtonFunction}
            />
            <Grid
              item
              className = {
                size === 'l' ? classes.changeAccountL
                : size === 'm' ? classes.changeAccountM
                : classes.changeAccountS
              }
            >
              <Typography
                variant = 'h4'
              >
                {words.notYou}
                <span
                  className = {classes.blueText}
                  onClick = {forgotFunction('account')}
                >
                  {words.anotherAccount}
                </span>
              </Typography>
            </Grid>
          </Fragment>
        )
      }
    }
  }

  if (userValue.length === 0 || passwordValue.length === 0) {
    disabled = true;
  } else {
    disabled = false;
  }

  return(
  <Fragment>
    <LoginHeader
      darkFlag = {darkFlag}
      logo = {logo}
      practiceName = {practiceName}
      size = {size}
      words = {words.loginHeader}
    />
    <UserInputs
      darkFlag = {darkFlag}
      passwordValue = {passwordValue}
      size = {size}
      showPassword = {showPassword}
      words = {words.userInputs}
      userValue = {userValue}
      handlePreventDefault = {handlePreventDefault}
      handleToggle = {handleToggle}
      userInputFunction = {userInputFunction}
    />
    <RememberForgot
      darkFlag = {darkFlag}
      rememberUserToggle = {rememberUserToggle}
      size = {size}
      words = {words.rememberForgot}
      forgotFunction = {forgotFunction}
      handleToggle = {handleToggle}
    />
    <ActionButton
      darkFlag = {darkFlag}
      disabled = {disabled}
      size = {size}
      type = 'signInFresh'
      words = {words.actionButton}
      actionButtonFunction = {actionButtonFunction}
    />
    <Grid
      item
      className = {
        size === 'l' ? classes.orGridL
        : size === 'm' ? classes.orGridM
        : classes.orGridS
      }
    >
      <Grid
        container
        alignItems = 'center'
        justify = 'center'
        style = {{width: '100%'}}
      >
        <Grid
          item
          className = {classes.divider}
        ></Grid>
        <Grid
          item
          className = {
            size === 'l' ? classes.orTextGridL
            : size === 'm' ? classes.orTextGridM
            : classes.orTextGridS
          }
        >
          <Typography
            align = 'center'
            variant = {
              size === 'l' ? 'h5'
              : 'h6'
            }
          >
            {words.or}
          </Typography>
        </Grid>
        <Grid
          item
          className = {classes.divider}
        ></Grid>
      </Grid>
    </Grid>
    <SocialButtons
      darkFlag = {darkFlag}
      login = {login}
      size = {size}
      buttonFunction = {buttonFunction}
    />
  </Fragment>
  );
}

export default withStyles(styles)(Login);