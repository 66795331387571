import React, { Component, Fragment } from 'react';
import { AppBar, IconButton, Toolbar, withStyles, Typography, Drawer} from '@material-ui/core';

// import MenuIcon from '@material-ui/icons/InfoRounded';
import MenuIcon from '../../../../static/svgs/PracticeInfo.svg';
import MenuIconL from '../../../../static/svgs/PracticeInfoL.svg';

// import poweredByYAPI from '../../../../static/svgs/PoweredbyYAPI.svg';

import SideBar from './SideBar/SideBar';
// import LargeBar from './LargeBar/LargeBar';
// import LargeBottomBar from './LargeBottomBar/LargeBottomBar';

const styles = theme => ({
  menuButtonS: {
    marginRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '6px',
    paddingRight: '0px',
  },
  menuButtonM: {
    marginRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  menuButtonL: {
    marginRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  grow: {
    flexGrow: 1,
    paddingLeft: '16px',
  },
  growL: {
    width: '468px',
    maxWidth: '468px',
  },
  appBarS: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  appBarM: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  appBarL: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  bottomAppBarS: {
    top: 'auto',
    bottom: '0px',
    backgroundColor: theme.palette.background.default,
  },
  bottomAppBarM: {
    top: 'auto',
    bottom: '0px',
    backgroundColor: theme.palette.background.default,
  },
  bottomAppBarL: {
    top: 'auto',
    bottom: '0px',
    // backgroundColor: '#FEE9D1',
    backgroundColor: theme.palette.text.darkOrange,
  },
  bottomBarS: {
    zIndex: 1001,
    height: '34px',
    minHeight: '34px',
    // maxWidth: '375px',
    // left: '50%',
    // transform: 'translate(-50%, 0%)',
    backgroundColor: theme.palette.background.default,
  },
  bottomBarM: {
    zIndex: 1001,
    height: '72px',
    minHeight: '72px',
    maxWidth: '768px',
    left: '50%',
    // transform: 'translate(-50%, 0%)',
    backgroundColor: theme.palette.background.default,
  },
  bottomBarL: {
    zIndex: 1001,
    height: '70px',
    minHeight: '70px',
    maxWidth: '866px',
    left: '50%',
    // transform: 'translate(-50%, 0%)',
    // backgroundColor: '#FEE9D1',
    backgroundColor: theme.palette.text.darkOrange
  },
  navBarS: {
    zIndex: 1001,
    // height: '22px',
    minHeight: '22px',
    // maxWidth: '375px',
    // left: '50%',
    // transform: 'translate(-50%, 0%)',
    // backgroundColor: 'rgb(255, 255, 255)',
    // marginTop: '8px',
    paddingTop: '8px',
    paddingLeft: '0px',
    paddingRight: '16px',
  },
  navBarM: {
    zIndex: 1001,
    // height: '31px',
    minHeight: '31px',
    // maxWidth: '768px',
    // left: '50%',
    // transform: 'translate(-50%, 0%)',
    // backgroundColor: 'rgb(255, 255, 255)',
    // marginTop: '24px',
    paddingTop: '24px',
    paddingLeft: '0px',
    paddingRight: '24px',
  },
  navBarL: {
    zIndex: 1001,
    // height: '21px',
    minHeight: '21px',
    // width: '100%',
    // maxWidth: '866px',
    // left: '50%',
    // transform: 'translate(-50%, 0%)',
    // backgroundColor: 'rgb(255, 255, 255)',
    paddingTop: '24px',
    paddingRight: '32px',
  },
  iconS: {
    width: '22px',
    height: '22px',
  },
  iconM: {
    width: '31px',
    height: '31px',
  },
  iconL: {
    width: '40px',
    height: '40px',
  },
  imageS: {
    height: '18px',
    width: '92px',
    margin: 'auto',
    marginBottom: '16px'
  },
  imageM: {
    height: '24px',
    width: '124px',
    margin: 'auto',
    marginBottom: '48px',
  },
  fullText: {
    width: '100%',
    paddingLeft: '16px',
  }
})

class NavBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDrawerOpen: false
    }
  }

  toggleDrawer = () => {
    const drawer = this.state.isDrawerOpen;
    this.setState({
      isDrawerOpen: !drawer
    });
  }

  render() {
    const {
      classes,
      data,
      darkFlag,
      error,
      size,
      showMenu,
      title,
    } = this.props;
      return (
        <Fragment>
          <AppBar
            position = 'fixed'
            elevation = {0}
            id = 'navbar'
            className = {
              size === 'l' ? classes.appBarL
              : size === 'm' ? classes.appBarM
              : classes.appBarS
            }
          >
            <Toolbar
              className = {
                size === 'l' ? classes.navBarL
                : size === 'm' ?  classes.navBarM
                : classes.navBarS
              }
              variant = 'dense'
            >
              { showMenu !== 1 && data.back_url ? null : (
                <Typography
                  variant = 'h4'
                  color = 'inherit'
                  className = {
                    error ? classes.fullText 
                    : classes.grow
                  }
                  noWrap
                >
                  {title}
                </Typography>
              )}
              { parseInt(showMenu) === 1 ? (
                <IconButton
                  className = {
                    size === 'l' ? classes.menuButtonL
                    : size === 'm' ?  classes.menuButtonM
                    : classes.menuButtonS
                  }
                  color = 'primary'
                  aria-label = 'Menu'
                  onClick = {() => {this.toggleDrawer()}}
                >
                  <img
                    src = {
                      size === 's' ? MenuIcon
                      : MenuIconL
                    }
                    alt = 'Practice Information'
                    className = {
                      size === 'l' ? classes.iconL
                      : size === 'm' ? classes.iconM 
                      : classes.iconS
                    }
                  />
                </IconButton>
              ) :  null}
            </Toolbar>
          </AppBar>
          <Drawer
            anchor = 'right'
            open = {this.state.isDrawerOpen}
            transitionDuration = {350}
            variant = 'temporary'
            onClose = {this.toggleDrawer}
          >
            <SideBar
              address = {data.address}
              darkFlag = {darkFlag}
              email = {data.email}
              place_id = {data.google_place_id}
              size = {size}
              telephone = {data.phone}
              website = {data.web}
              toggleDrawer = {this.toggleDrawer}
            />
          </Drawer>
        </Fragment>
      );
    }
  // }
}

export default withStyles(styles)(NavBar);
