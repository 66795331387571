import React, {
  Fragment,
} from 'react';

import {
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import defaultLogo from '../../../../static/pngs/defaultLogo.png';

const styles = theme => ({
  imageGridS: {
    marginTop: '40px',
  },
  imageGridM: {

  },
  imageGridL: {

  },
  imageS: {
    height: '78px',
    width: '78px',
  },
  imageM: {
    height: '142px',
    width: '142px',
  },
  imageL: {
    height: '142px',
    width: '142px',
  },
  patientGridS: {
    marginBottom: '24px',
  },
  patientGridM: {
    marginBottom: '48px',
  },
  patientGridL: {
    marginBottom: '32px',
  },
  welcomeS: {
    marginTop: '16px',
    marginBottom: '27px',
  },
  welcomeM: {
    marginTop: '28px',
    marginBottom: '48px',
  },
  welcomeL: {
    marginTop: '40px',
    marginBottom: '32px',
  },
});

const LoginHeader = (props) => {
  const {
    classes,
    logo,
    patientName,
    practiceName,
    size,
    words,
  } = props;
  return(
    <Fragment>
      <Grid
        item
        className = {
          size === 'l' ? classes.imageGridL
          : size === 'm' ? classes.imageGridM
          : classes.imageGridS
        }
      >
        <img
          alt = {practiceName}
          src = {logo ? logo : defaultLogo}
          className = {
            size === 'l' ? classes.imageL
            : size === 'm' ? classes.imageM
            : classes.imageS
          }
        />
      </Grid>
      {practiceName && (
        <Grid
          item
          className = {
            size === 'l' ? classes.welcomeL 
            : size === 'm' ? classes.welcomeM
            : classes.welcomeS
          }
        >
          <Typography
            align='center'
            variant={
              size === 'l' ? 'body1'
              : 'h2'
            }
          >
            {words.welcome}
          </Typography>
          <Typography
            align = 'center'
            variant = {
              size === 's' ? 'subtitle1'
              : 'body2'
            }
          >
            {practiceName}
          </Typography>
        </Grid>
      )}
      {patientName && (
        <Grid
          item
          className = {
            size === 'l' ? classes.patientGridL
            : size === 'm' ? classes.patientGridM
            : classes.patientGridS
          }
        >
          <Typography
            variant = {
              size === 's' ? 'subtitle1'
              : 'subtitle1'
            }
          >
            {patientName}
          </Typography>
        </Grid>
      )}
    </Fragment>
  );
}

export default withStyles(styles)(LoginHeader);