import React, { Fragment }  from 'react';
import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
  main: {
    height: '609px',
    width: '866px',
  },
  mainEmail: {
    height: '238px',
    width: '866px'
  },
  mainConfirm: {
    height: '629px',
    width: '866px',
  },
  mainDeets: {
    height: '641px',
    width: '866px',
  },
  mainButtons: {
    minHeight: '609px',
    width: '866px',
    overflowY: 'auto',
  },
  mainCA: {
    height: '429px',
    width: '866px',
  },
  mainOwari: {
    height: '591px',
    width: '866px',
  },
  mainTochuu: {
    height: '500px',
    width: '866px',
  },
  mainFukushuu: {
    height: '712px',
    width: '866px',
  },
  mainError: {
    height: '100vh',
    width: '958px',
    margin: 'auto',
  },
  leftGrid: {
    width: '500px',
    maxWidth: '500px',
    height: '100%',
  },
  leftGridError: {
    width: '378px',
    maxWidth: '378px',
    height: '100%',
  },
  rightGrid: {
    height: '100%',
    width: '366px',
  },
  rightGridError: {
    height: '100%',
    width: '580px',
  },
  column: {
    height: '100%',
  },
  tripod: {
    width: '100%',
    maxWidth: '866px',
  },
  tripodOutside: {
    width: '100%',
    maxWidth: '866px',
    marginBottom: '70px',
  }

});

const LargeView = (props) => {
  const {
    classes,
    leftColumn,
    rightColumn,
    customSize,
    tripod,
  } = props;
  return(
    <Fragment>
      <Grid
        container
        direction='row'
        alignItems='center'
        justify='space-between'
        className={customSize === 'email' ? classes.mainEmail : customSize === 'confirm' ? classes.mainConfirm : customSize === 'deets' ? classes.mainDeets : customSize === 'button' ? classes.mainButtons : customSize === 'ca' ? classes.mainCA : customSize === 'owari' ? classes.mainOwari : customSize === 'tochuu' ? classes.mainTochuu : customSize === 'fukushuu' ? classes.mainFukushuu : customSize === 'error' ? classes.mainError : classes.main}
      >
        <Grid
          item
          className={customSize === 'error' ? classes.leftGridError : classes.leftGrid}
        >
          <Grid
            container
            direction='column'
            justify={customSize === 'error' ? 'center' : 'space-between'}
            alignItems='flex-start'
            className={classes.column}
          >
            {leftColumn}
          </Grid>
        </Grid>
        <Grid
          item
          className={customSize === 'error' ? classes.rightGridError : classes.rightGrid}
        >
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='flex-end'
            className={classes.column}
          >
            {rightColumn}
          </Grid>
        </Grid>
      </Grid>
        {tripod && (
          <Grid
            container
            className={classes.tripodOutside}
          >
            <Grid
              item
              className={classes.tripod}
            >
              {tripod}
            </Grid> 
          </Grid>
        )}
    </Fragment>
    
  );
}

export default withStyles(styles)(LargeView);