import React from 'react';
import { Grid, withStyles } from '@material-ui/core';

import SideBarHeader from './SideBarHeader/SideBarHeader';
import SideBarRow from './SideBarRow/SideBarRow';

import PoweredByYAPI from '../../../../../static/svgs/PoweredbyYAPI.svg';
import PoweredByYAPIDark from '../../../../../static/svgs/PoweredbyYAPIDark.svg';
import PhoneIcon from '../../../../../static/svgs/CallUs.svg';
import EmailIcon from '../../../../../static/svgs/Email.svg';
import HomeIcon from '../../../../../static/svgs/Location.svg';
import WorldIcon from '../../../../../static/svgs/Website.svg';

const styles = theme => ({
  buttonsL: {
    // width: '374px',
    padding: '0px 16px 0px 32px',
  },
  mainS: {
    maxWidth: '195px',
    width: '100%'
  },
  mainM: {
    maxWidth: '259px'
  },
  mainL: {
    maxWidth: '438px',
    // width: '100%',
  },
  poweredByGridS: {
    position: 'absolute',
    bottom: '54px',
    transform: 'translate(-50%, 0)',
    left: '50%',
  },
  poweredByGridM: {
    position: 'absolute',
    bottom: '76px',
    transform: 'translate(-50%, 0)',
    left: '50%',
  },
  poweredByGridL: {
    position: 'absolute',
    bottom: '56px',
    transform: 'translate(-50%, 0)',
    left: '50%',
  },
  poweredByS: {
    height: '17px',
    width: '88px',
  },
  poweredByM: {
    height: '26px',
    width: '134px',
  },
  poweredByL: {
    height: '24px',
    width: '124px',
  },
  subMainS: {
    padding: '0px 27px 0px 27px',
  },
  subMainM: {
    padding: '0px 45px 0px 45px',
  },
});

const iconSizes = {
  pS: {width: '14px', height: '14px', margin: 'auto'},
  pM: {width: '20px', height: '20px', margin: 'auto'},
  pL: {width: '20px', height: '20px', margin: 'auto'},
  eS: {width: '17px', height: '12px', margin: 'auto'},
  eM: {width: '22px', height: '16px', margin: 'auto'},
  eL: {width: '22px', height: '16px', margin: 'auto'},
  aS: {width: '13px', height: '17px', margin: 'auto'},
  aM: {width: '19px', height: '26px', margin: 'auto'},
  aL: {width: '19px', height: '26px', margin: 'auto'},
  wS: {width: '15px', height: '15px', margin: 'auto'},
  wM: {width: '19px', height: '19px', margin: 'auto'},
  wL: {width: '19px', height: '19px', margin: 'auto'},
}

const SideBar = (props) => {
  const {
    classes,
    address,
    darkFlag,
    email,
    place_id,
    telephone,
    website,
    toggleDrawer,
    size,
  } = props;

  if (size === 'l') {
    return (
      <Grid
        container
        direction = 'column'
        className = {classes.mainL}
      >
        <Grid
          item
          className = {classes.subMainL}
        >
          <SideBarHeader
            darkFlag = {darkFlag}
            size = {size}
            toggleDrawer = {toggleDrawer}
          />
        </Grid>
        <Grid
          item
          className = {classes.buttonsL}
        >
          <Grid
            container
            // justify = 'space-evenly'
          >
            {telephone && (
              <SideBarRow
                icon = {
                  <img
                    src = {PhoneIcon}
                    alt = 'Call Us'
                    style = {iconSizes.pL}
                  />
                }
                link = {`tel:+1${telephone}`}
                size = {size}
                text = 'Call Us'
                tooltipText = {telephone}
              />
            )}
            {email && (
              <SideBarRow
                icon = {
                  <img
                    src = {EmailIcon}
                    alt = 'Email Us'
                    style = {iconSizes.eL} 
                  />
                }
                link = {`mailto:${email}`}
                size = {size}
                text = 'Email Us'
                tooltipText = {email}
              />
            )}
            {address && place_id && (
              <SideBarRow
                icon = {
                  <img
                    src = {HomeIcon} 
                    alt = 'Our Location' 
                    style = {iconSizes.aL} 
                  />
                }
                link = {`https://www.google.com/maps/search/?api=1&query=${escape(address)}&query_place_id=${place_id}`}
                size = {size}
                text = 'Find Us'
                tooltipText = {address}
              />
            )}
            {website && (
              <SideBarRow
                icon = {
                  <img 
                    src = {WorldIcon} 
                    alt = 'Our Website' 
                    style = {iconSizes.wL} 
                  />
                }
                link = {website}
                size = {size}
                text = 'Visit Us'
                tooltipText = {website}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          className = {classes.poweredByGridL}
        >
          <img
            alt = 'Powered by YAPI'
            className = {classes.poweredByL}
            src = {
              darkFlag ? PoweredByYAPIDark
              : PoweredByYAPI
            }
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        direction = 'column'
        className = {
          size === 'm' ? classes.mainM 
          : classes.mainS
        }
      >
        <Grid
          item
          className = {
            size === 'm' ? classes.subMainM
            : classes.subMainS
          }
        >
          <SideBarHeader
            darkFlag = {darkFlag}
            size = {size}
            toggleDrawer = {toggleDrawer}
          />
          {telephone && (
            <SideBarRow
              icon = {
                <img
                  src = {PhoneIcon}
                  alt = 'Call Us'
                  style = {
                    size === 'm' ? iconSizes.pM
                    : iconSizes.pS
                  }
                />
              }
              link = {`tel:+1${telephone}`}
              size = {size}
              text = 'Call Us'
            />
          )}
          {email && (
            <SideBarRow
              icon = {
                <img
                  src = {EmailIcon}
                  alt = 'Email Us'
                  style = {
                    size === 'm' ? iconSizes.eM
                    : iconSizes.eS
                  } 
                />
              }
              link = {`mailto:${email}`}
              size = {size}
              text = 'Email Us'
            />
          )}
          {address && place_id && (
            <SideBarRow
              icon = {
                <img
                  src = {HomeIcon} 
                  alt = 'Our Location' 
                  style = {
                    size === 'm' ? iconSizes.aM 
                    : iconSizes.aS
                  } 
                />
              }
              link = {`https://www.google.com/maps/search/?api=1&query=${escape(address)}&query_place_id=${place_id}`}
              size = {size}
              text = 'Find Us'
            />
          )}
          {website && (
            <SideBarRow
              icon = {
                <img 
                  src = {WorldIcon} 
                  alt = 'Our Website' 
                  style = {
                    size === 'm' ? iconSizes.wM 
                    : iconSizes.wS
                  } 
                />
              }
              link = {website}
              size = {size}
              text = 'Visit Us'
            />
          )}
        </Grid>
        <Grid
          item
          className = {
            size === 'm' ? classes.poweredByGridM 
            : classes.poweredByGridS
          }
        >
          <img
            alt = 'Powered by YAPI'
            className = {
              size === 'm' ? classes.poweredByM 
              : classes.poweredByS
            }
            src = {
              darkFlag ? PoweredByYAPIDark
              : PoweredByYAPI
            }
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SideBar);
